import * as React from 'react';
export default class ContactInfo extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="contactInfo mB40">
                    <h2>{this.props.Title}</h2>
                    <p className="mB10"><strong>{this.props.SubTitle}</strong></p>
                    <p className="location mB10">{this.props.Adres}</p>
                    <p className="phone mB10"><a href={"tel:" + this.props.TelefonBitisik}>{this.props.Telefon}</a></p>
                    <p className="mail"><a href={"mailto:" + this.props.Email}>{this.props.Email}</a></p>
                </div>
            </React.Fragment>
        );
    }
}