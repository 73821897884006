import * as React from 'react';
import { siteApi } from '../../Utils';
import service from 'axios';
import $ from 'jquery';
import notify from 'devextreme/ui/notify';
export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Ad: "",
            Soyad: "",
            TelUcHane: "",
            TelYediHane: "",
            EpostaAdresi: "",
            Mesaj: ""
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="contactForm pT40">
                    <h2>İletişim Formu</h2>
                    <p>Öneri, şikayet ve dilekleriniz için bizimle iletişime geçin</p>
                    <div className="formContainer d-flex flex-wrap mT30">
                        <div className="formItem col-6 pB20">
                            <label htmlFor="">Adınız</label>
                            <input type="text" className="inputText contact-name" placeholder="Adınız" onChange={(e) => {
                                this.setState({
                                    Ad: e.target.value
                                })
                            }} />
                        </div>
                        <div className="formItem col-6 pL50 pB20">
                            <label htmlFor="">Soyadınız</label>
                            <input type="text" className="inputText contact-surname" placeholder="Soyadınız" onChange={(e) => {
                                this.setState({
                                    Soyad: e.target.value
                                })
                            }} />
                        </div>
                        <div className="formItem col-6 pB20">
                            <label htmlFor="">Mobil Telefon Numaranız</label>
                            <div className="col-12 d-flex">
                                <div className="col-2"><input type="text" maxLength="3" className="inputText contact-mobil1" placeholder="5XX" onChange={(e) => {
                                    this.setState({
                                        TelUcHane: e.target.value
                                    })
                                }} /></div>
                                <div className="col-10 pL10"><input type="text" maxLength="7" className="inputText contact-mobil2" placeholder="XXX XX XX" onChange={(e) => {
                                    this.setState({
                                        TelYediHane: e.target.value
                                    })
                                }} /></div>
                            </div>
                        </div>
                        <div className="formItem col-6 pL50 pB20">
                            <label htmlFor="">E-posta Adresiniz</label>
                            <input type="text" className="inputText contact-eposta" placeholder="isim@firmaismi.com" onChange={(e) => {
                                this.setState({
                                    EpostaAdresi: e.target.value
                                })
                            }} />
                        </div>
                        <div className="formItem col-12 pB20">
                            <label htmlFor="">Mesajınız</label>
                            <textarea name="" id="" className="txtArea contact-mesaj" placeholder="Bir şeyler yazın..."
                                onChange={(e) => {
                                    this.setState({
                                        Mesaj: e.target.value
                                    })
                                }}
                            ></textarea>
                        </div>
                        <div className="formItem col-12 pB20 align-items-end">
                            <button className="contactFormSend" onClick={() => {
                                var data = {
                                    Ad: this.state.Ad,
                                    Soyad: this.state.Soyad,
                                    Mesaj: this.state.Mesaj,
                                    TelUcHane: this.state.TelUcHane,
                                    TelYediHane: this.state.TelYediHane,
                                    EpostaAdresi: this.state.EpostaAdresi
                                }
                                service.post(siteApi + "sitearayuz/postContactForm",
                                    data, null)
                                    .then(res => {
                                        if (res.data.Success) {
                                            $('.contactForm').html("Başvurunuz alındı, teşekkür ederiz.");
                                        } else {
                                            notify("Başvurunuz alınamadı, teknik bir hata oluştu." + res.data.ErrorMessage, "error", 3000);
                                        }

                                    })
                                    .catch(error => {
                                        notify("Başvurunuz alınamadı, teknik bir hata oluştu." + error, "error", 3000);
                                    })
                            }}>BAŞVUR</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}