import * as React from 'react';
import { firmaid, siteApi } from '../../Utils';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import FileUploader from 'devextreme-react/file-uploader';
import $ from 'jquery';
export default class InsanKaynaklariForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Ad: "",
            Soyad: "",
            DosyaAdi: ""
        }
        this.onFileUploaded = this.onFileUploaded.bind(this);
    }
    onFileUploaded(e) {
        var res = JSON.parse(e.request.response);
        if (!res.Success) {
            notify("Dosyanız yüklenemedi," + res.ErrorMessage, "error", 5000);

            e.component.reset();
        }
        else {
            notify("Dosyanız yüklendi", "success", 5000);
            this.setState({
                DosyaAdi: res.Data
            });
            e.component.reset();
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="ikForm mT30">
                    <div className="formContainer d-flex flex-wrap mT30">
                        <div className="formItem col-6 pB20">
                            <label htmlFor="">Adınız</label>
                            <input type="text" className="inputText ik-name" placeholder="Adınız" onChange={(e) => {
                                this.setState({
                                    Ad: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-6 pL50 pB20">
                            <label htmlFor="">Soyadınız</label>
                            <input type="text" className="inputText ik-surname" placeholder="Soyadınız" onChange={(e) => {
                                this.setState({
                                    Soyad: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-6">
                            <label htmlFor="">Cv'nizi Yükleyin</label>
                            <FileUploader selectButtonText="Dosya seçiniz" labelText="" className="fileinput" accept=".doc,.docx,.pdf"
                                uploadUrl={siteApi + "sitearayuz/İnsanKaynaklariFormUpload"}
                                uploadMode="instantly"
                                maxFileSize={4000000}
                                name='myFile'
                                onUploaded={this.onFileUploaded}
                            />

                        </div>
                        <div className="formItem col-6 pL50">
                            <label htmlFor="">&nbsp;</label>
                            <button className="ikFormSend" onClick={() => {

                                var name = $('.ik-name').val();
                                var surname = $('.ik-surname').val();

                                if (name === '') {
                                    $('.ik-name').addClass('warnInput');
                                    $('.ik-name').attr('placeholder', 'Lütfen adınızı giriniz...');

                                } else {
                                    $('.ik-name').removeClass('warnInput');
                                    $('.ik-name').attr('placeholder', 'Adınız');
                                }

                                if (surname === '') {
                                    $('.ik-surname').addClass('warnInput');
                                    $('.ik-surname').attr('placeholder', 'Lütfen soyadınızı giriniz...');
                                } else {
                                    $('.ik-surname').removeClass('warnInput');
                                    $('.ik-surname').attr('placeholder', 'Soyadınız');
                                }

                                if (name === '' | surname === '')
                                    return;

                                var data = {
                                    Ad: this.state.Ad,
                                    Soyad: this.state.Soyad,
                                    DosyaAdi: this.state.DosyaAdi,
                                    Firma_Id:firmaid
                                }
                                service.post(siteApi + "sitearayuz/postIkForm",
                                    data, null)
                                    .then(res => {
                                        if (res.data.Success) {
                                            $('.ikForm .formContainer').html('<span class="success">Form başarıyla gönderildi <br/> Zaman ayırdığınız için teşekür ederiz</span>')
                                        } else {
                                            notify("Başvurunuz alınamadı, teknik bir hata oluştu." + res.data.ErrorMessage, "error", 3000);
                                        }

                                    })
                                    .catch(error => {
                                        notify("Başvurunuz alınamadı, teknik bir hata oluştu." + error, "error", 3000);
                                    })
                            }}>BAŞVUR</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}