import * as React from "react";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.carmine.css';
export default class BlankLayout extends React.Component {
    
    render() {
        return (<React.Fragment>
            {this.props.children}
        </React.Fragment>)
    }
}