import * as React from 'react';
export default class RightTop extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="rightTop">
                    <div className="shareIcons">
                        <ul>
                            <li><a href={this.props.facebookadresi} rel="noreferrer" target={(this.props.SayfaIciLink === false || this.props.SayfaIciLink === undefined) ? "_blank" : "_self"}><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href={this.props.twitteradresi} rel="noreferrer" target={(this.props.SayfaIciLink === false || this.props.SayfaIciLink === undefined) ? "_blank" : "_self"}><i className="fab fa-twitter"></i></a></li>
                            <li><a href={this.props.youtubeadresi} rel="noreferrer" target={(this.props.SayfaIciLink === false || this.props.SayfaIciLink === undefined) ? "_blank" : "_slef"}><i className="fab fa-youtube"></i></a></li>
                            <li><a href={this.props.linkedinadresi} rel="noreferrer" target={(this.props.SayfaIciLink === false || this.props.SayfaIciLink === undefined) ? "_blank" : "_self"}><i className="fab fa-linkedin-in"></i></a></li>
                            <li><a href={this.props.instagramadresi} rel="noreferrer" target={(this.props.SayfaIciLink === false || this.props.SayfaIciLink === undefined) ? "_blank" : "_self"}><i className="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div className="phone"><a href={"tel:" + this.props.telefonbitisik}>{this.props.telefon}</a></div>
                    <a className="franchise-btn" href="/franchise/teksos-franchise">FRANCHISE BAŞVURU</a>
                </div>
            </React.Fragment>
        );
    }
}