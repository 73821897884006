import * as React from 'react';
import { siteApi } from '../../Utils';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import $ from 'jquery';
export default class Form extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ulkeler: [],
            iller: [],
            ilceler: [],
            Ulke_Id: 0,
            Il_Id: 0,
            Ilce_Id: 0,
            Ad: "",
            Soyad: "",
            Gsm: "",
            Email: "",
            Telefon: "",
            Adres: "",
            TcNo: "",
            KapiNo: "",
            AdresTarifi: "",
            Okudum: false
        }

        this.getUlkeler()
            .then(res => { })
            .catch(error => {
                notify(error, "error", 3000);
            })
    }

    //#region  servis metodları
    async getUlkeler() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getUlkeler", null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            ulkeler: res.data.Data
                        });
                        resolve("ülke bilgileri getirildi.");
                    }
                    else {
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })

    }
    async getIller(ulkeId) {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getIller/" + ulkeId, null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            iller: res.data.Data
                        });
                        resolve("il bilgileri getirildi.");
                    }
                    else {
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })

    }
    async getIlceler(ilid) {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getIlceler/" + ilid, null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            ilceler: res.data.Data
                        });
                        resolve("ilçe bilgileri getirildi.");
                    }
                    else {
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })

    }
    //#endregion
    render() {
        return (
            <React.Fragment>
                <div className="basvuruForm mT30">
                    <div className="formContainer d-flex flex-wrap mT30">
                        <div className="formItem col-6 pB20">
                            <label htmlFor="">Ülke</label>
                            <div className="selectbox">
                                <select name="" id="" className="inputDropdown basvuru-ulke" onChange={async (e) => {
                                    this.setState({
                                        Ulke_Id: parseInt(e.target.value)
                                    });
                                    await this.getIller(e.target.value)
                                        .then(res => {


                                        })
                                        .catch(() => {
                                            this.setState(
                                                {
                                                    Ulke_Id: 0,
                                                    iller: [],
                                                    ilceler: []
                                                });
                                        })
                                }}>
                                    <option value="0">Ülke Seçiniz</option>
                                    {this.state.ulkeler.map((ulke, i) => {
                                        return <option key={i} value={ulke.id}>{ulke.adi}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="formItem col-6 pL50 pB20">
                            <label htmlFor="">İl</label>
                            <div className="selectbox">
                                <select name="" id="" className="inputDropdown basvuru-il" onChange={async (e) => {
                                    this.setState({
                                        Il_Id: parseInt(e.target.value)
                                    });
                                    await this.getIlceler(e.target.value)
                                        .then(res => {

                                        })
                                        .catch(() => {
                                            this.setState(
                                                {
                                                    Il_Id: 0,
                                                    ilceler: []
                                                });
                                        })
                                }}>
                                    <option value="0">İl Seçiniz</option>
                                    {this.state.iller.map((il, i) => {
                                        return <option key={i} value={il.id}>{il.adi}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="formItem col-6 pB20">
                            <label htmlFor="">İlçe</label>
                            <div className="selectbox">
                                <select name="" id="" className="inputDropdown basvuru-ilce"
                                    onChange={(e) => {
                                        this.setState({
                                            Ilce_Id: parseInt(e.target.value)
                                        })
                                    }}
                                >
                                    <option value="0">İlçe Seçiniz</option>
                                    {this.state.ilceler.map((ilce, i) => {
                                        return <option key={i} value={ilce.id}>{ilce.adi}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="formItem col-6 pB20 pL50">
                            <label htmlFor="">Adınız</label>
                            <input type="text" className="inputText basvuru-ad" placeholder="Adınız" onChange={(e) => {
                                this.setState({
                                    Ad: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-6  pB20">
                            <label htmlFor="">Soyadınız</label>
                            <input type="text" className="inputText basvuru-soyad" placeholder="Soyadınız" onChange={(e) => {
                                this.setState({
                                    Soyad: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-6 pL50 pB20">
                            <label htmlFor="">Gsm</label>
                            <input type="text" className="inputText basvuru-gsm" maxLength="10" placeholder="5XX XXX XX XX" onChange={(e) => {
                                this.setState({
                                    Gsm: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-6  pB20">
                            <label htmlFor="">Sabit Telefon Numaranız</label>
                            <input type="text" className="inputText basvuru-telefon" maxLength="10" placeholder="1XX XXX XX XX" onChange={(e) => {
                                this.setState({
                                    Telefon: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-6 pL50 pB20">
                            <label htmlFor="">E-posta Adresiniz</label>
                            <input type="text" className="inputText basvuru-email" placeholder="isim@firmaismi.com" onChange={(e) => {
                                this.setState({
                                    Email: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-6 pB20">
                            <label htmlFor="">T.C. No</label>
                            <input type="text" className="inputText basvuru-tcno" placeholder="T.C. No" maxLength="11" onChange={(e) => {
                                this.setState({
                                    TcNo: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-6 pL50 pB20">
                            <label htmlFor="">Kapı No</label>
                            <input type="text" className="inputText basvuru-kapino" placeholder="Kapı No" onChange={(e) => {
                                this.setState({
                                    KapiNo: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-12  pB20">
                            <label htmlFor="">Adres</label>
                            <input type="text" className="inputText basvuru-adres" placeholder="Adres" onChange={(e) => {
                                this.setState({
                                    Adres: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-12 pB20">
                            <label htmlFor="">Adres tarifi</label>
                            <input type="text" className="inputText basvuru-adrestarifi" placeholder="Adres Tarifi" onChange={(e) => {
                                this.setState({
                                    AdresTarifi: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-12 pB20" style={{ maxHeight: "500px", overflow: "auto" }}>
                            <p>
                                <b>KURYE ÇAĞIR MÜŞTERİ AYDINLATMA METNİ</b><br></br>
                            BY EXPRESS Servisi A.Ş. (Kısaca “BY EXPRESS”) olarak 6698 sayılı Kişisel Verilerin Korunması Kanunu’nda tanımlı veri sorumlusu sıfatıyla işlediğimiz kişisel verilerinizin güvenliğine önem veriyoruz. Bu nedenle sizleri kişisel verilerinizin işlenmesine ilişkin olarak bilgilendirmek isteriz.
                        </p>
                            <p>
                                KİŞİSEL VERİLERİNİZİN İŞLENME AMACI VE HUKUKİ SEBEBİ<br></br>
                                Kişisel verileriniz 6698 sayılı Kişisel Verilerin Korunması Kanunu ve ikincil düzenlemelere uygun olarak aşağıda belirtilen amaçlar ve hukuki sebepler çerçevesinde işlenmektedir.
                        </p>
                            <p>
                                Web sitemiz üzerinde online işlemler menüsünden “Kurye Çağır” fonksiyonunu kullanmanız durumunda “Ad-soyad, T.C. kimlik No, GSM Numarası” gibi kişisel verileriniz ile kargo hizmeti temin etmeniz kapsamında<b> “Kimlik Bilgisi, İletişim Bilgisi, Müşteri İşlem Bilgisi, Görsel ve İşitsel Kayıtlar, Risk Yönetim Bilgisi ve Finansal Bilgi”</b> kategorilerine giren kişisel verileriniz;
                        </p>
                            <p>
                                Kargo hizmet talebinizin web sitesi üzerinden alınması, tarafınıza kargo hizmetinin sunulması, hizmetlerin ifası ve ispatı, müşteri kayıtlarının oluşturulması, ödemelerin alınması, tazmin ödemelerinin veya ücret iadelerinin yapılması ve bu ödemelerin kaydının tutulması, gönderilerin takip edilmesi, gönderi sorgulamalarının yapılması, gerekmesi durumunda müşteriler ile iletişim kurulması, teminat işlemlerinin yapılması ve ödeme risklerinin belirlenmesi, tahsilat işlemlerinin yürütülmesi, hesap mutabakatlarının sağlanması amaçlarıyla 6698 sayılı Kanunun 5/2-c maddesindeki  “Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması” hukuki sebep uyarınca,
                        </p>
                            <p>
                                Müşterilerin kampanyalardan yararlandırılmasının sağlanması, kampanya hazırlanması, gönderilere ilişkin daha iyi hizmetlerin sunulması, müşterilerin kargo operasyonları hakkında bilgilendirilmesi amacıyla analiz ve raporlama çalışmalarının yapılması amaçlarıyla 6698 sayılı Kanunun <b>5/2-f</b> maddesindeki “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebebi uyarınca,
                        </p>
                            <p>
                                4925 sayılı Karayolu Taşıma Kanunu, Karayolu Taşıma Yönetmeliği, 6475 sayılı Posta Hizmetleri Kanunu ve Bilgi Teknolojileri ve İletişim Kurulu’nun 27/12/2016 tarih ve 2016/DK-YED/517 sayılı kararı ile onaylanan Posta Gönderilerine İlişkin Güvenlik Tedbirlerine Yönelik Usul ve Esasları’nda gönderici, alıcı, kargoyu teslim eden ve alanların kişisel verilerinin işlenmesine dair düzenlemeler mevcuttur. Belirtilen mevzuat gereğince;
                        </p>
                            <p>
                                Gönderinin kabulü aşamasında alınarak kaydedilmesi zorunlu asgari bilgiler;
                        </p>
                            <p>
                                Gönderici gerçek kişi ise; adı-soyadı, TC Kimlik numarası, açık adres, telefon numarası, gönderinin farklı bir kişi tarafından getirilmesi halinde bunlara ek olarak gönderiyi teslim eden kişinin adı-soyadı, T.C. kimlik numarası, açık adresi, telefon numarası,
                        </p>
                            <p>
                                Gönderici tüzel kişi ise tam unvanı, VKN veya MERSİS numarası, açık adresi, telefon numarası, bunun yanında tüzel kişinin gönderisini göndermeye yetkili kişinin; adı-soyadı, TC Kimlik numarası, açık adres, telefon numarası
                                </p>
                            <p>
                                Alıcının adı-soyadı/ünvanı ve açık adres bilgisi</p>
                            <p>
                                Gönderinin cinsi(sözleşme kapsamında fatura ya da irsaliye ile taşıttırılan gönderiler için alınmayabilir)</p>
                            <p>Gönderinin teslimi aşamasında alınarak kaydedilmesi zorunlu asgari bilgiler;</p>

                            <p>Hizmet sağlayıcılar tarafından, gönderilerin alıcıya(gerçek veya tüzel kişi) teslimi aşamasında, teslim alanın adı-soyadı, T.C. kimlik numarası alınır ve kaydedilir.</p>
                            <p>(Yukarıdaki işlemlerde yabancı uyruklular için; pasaport numarası, uluslararası geçerliliği olan muadili belgenin numarası veya T.C. yetkili mercilerince verilmiş kimliği tespite yarayan belge numarası alınır ve kaydedilir.)
                        </p>
                            <p>
                                213 sayılı Vergi Usul Kanunu, 6762 sayılı Türk Ticaret Kanunu, 4925 sayılı Karayolu Taşıma Kanunu, Karayolu Taşıma Yönetmeliği, 6475 sayılı Posta Hizmetleri Kanunu ve Bilgi Teknolojileri ve İletişim Kurulu’nun 27/12/2016 tarih ve 2016/DK-YED/517 sayılı kararı ile onaylanan Posta Gönderilerine İlişkin Güvenlik Tedbirlerine Yönelik Usul ve Esasları ve ilgili diğer yasal düzenlemeler kapsamında yasal olarak alınması zorunlu bilgilerin kaydedilmesi, tanzimi gereken faturaların oluşturulması ve tarafınıza iletilmesi, vergisel kayıtların tutulması, hizmet sunumunu ispatlayan belgelerin düzenlenmesi, yasal yükümlülük kapsamındaki düzenli bildirimlerin ilgili kurumlara yapılması, resmi kurumlardan gelen taleplere cevap verilmesi ve diğer yasal kayıtların tutulması, saklama yükümlülüklerinin yerine getirilmesi, şikayet çözüm mekanizmasının işletilmesi, şüpheli işlem bildirimlerinin yapılması amacıyla 6698 sayılı Kanunun 5/2-a “Kanunlarda açıkça öngörülmesi” ve 5/2-ç “Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması” maddelerindeki hukuki sebepler uyarınca,
                        </p>
                            <p>
                                Gönderilerde zıya veya hasar oluşması durumunda bu hasarın tespiti, tazmin prosedürlerinin yürütülmesi, sigorta şirketlerine başvuru yapılması, olası ihtilaflarda ispat aracı olarak belge/kayıtların tanzimi ve saklanması, hizmet karşılığında ödemelerin yapılmaması veya tazmin iddiaları ve taşıma sözleşmesinden kaynaklanan sair ihtilaflar sebebiyle yasal hakların kullanılması gereken durumların ortaya çıkması hallerinde BY EXPRESS tarafından gerekli hukuki işlemler ve yasal süreçlerin yürütülmesi amacıyla 6698 sayılı Kanunu 5/2-e maddesindeki “Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması” hukuki sebebi uyarınca işlenebilecektir.
                        </p>
                            <p>
                                KİŞİSEL VERİLERİN AKTARILMASI
                        </p>
                            <p>
                                BY EXPRESS kişisel verilerinizi yukarıda sayılan amaçlar doğrultusunda kargo/posta gönderisinin taraflarıyla ve ilgilileriyle, tedarikçi bankalar, tedarikçi denetim şirketleri, tedarikçi sigorta şirketleri ve eksperler, acenteler ve transfer merkezi işletmecileri, alt taşıyıcılar gibi iş ortakları ve ifa yardımcılarıyla, hukuk, mali ve vergi danışmanları, denetçileri, hukuk ofisleri, Arıkanlı Holding A.Ş. ve bünyesinde bulunan topluluk şirketleriyle, yasal bildirimlerin yapılması kapsamında Gelir İdaresi Başkanlığı, Emniyet Teşkilatı, Bilgi Teknolojileri ve İletişim Kurumu ve diğer kanunen yetkili kamu kurum ve kuruluşlarıyla ayrıca talep edilmesi veya gerekmesi durumunda mahkemeler, Tüketici Hakem Heyetleri, Noterler, adli makamlar, Ulaştırma ve Altyapı Bakanlığı ve diğer kanunen yetkili kamu kurum ve kuruluşlarıyla ve/veya işin yerine getirilmesi için paylaşılması gereken üçüncü kişi iş ortaklarıyla Kanunun 8. ve 9. maddelerine uygun olarak paylaşılabilir.
                        </p>
                            <p>
                                KİŞİSEL VERİLERİN TOPLANMA YÖNTEMLERİ
                        </p>
                            <p>
                                BY EXPRESS kişisel verilerinizi kargo gönderisi yapmanız durumunda tarafınızdan, adınıza kargo gönderilmesi durumunda kargoyu gönderen kişi veya kurumlardan, mobil uygulamayı kullanmanız durumunda mobil uygulamamız üzerinden veya web sitesinde bilgilerinizi girmeniz durumunda web sitemiz üzerinden temin etmektedir. Bunun yanı sıra başka yöntemlerle BY EXPRESS ile iletişime geçerek açıkladığınız durumlarda da kişisel verileriniz toplanmaktadır.
                        </p>
                            <p>
                                KİŞİSEL VERİLERİNİZE İLİŞKİN HAKLARINIZ
                        </p>
                            <p>
                                Veri sahibi olarak BY EXPRESS’ ya başvurarak 6698 sayılı Kanunun 11. maddesindeki haklarınıza ilişkin taleplerinizi Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’de öngörülen başvuru usullerine uygun olarak iletmeniz durumunda BY EXPRESS talebinizi niteliğine göre en kısa sürede ve en geç 30 (otuz) gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi hâlinde, BY EXPRESS Kişisel Verileri Koruma Kurulunca belirlenen tarifedeki ücreti talep edebilecektir.
                        </p>
                            <p>
                                Detaylı bilgi için “https://www.byexpresskargo.com.tr” adresinde bulunan Kişisel Verilerin Korunması Politikamızı inceleyebilirsiniz.
                        </p>
                            <p>
                                BY EXPRESS tarafından kişisel verilerimin işlenmesi ve aktarılması süreçlerine ilişkin yöntem, amaç ve hukuki sebepler, aktarılan kişi grupları ile sahip olduğum haklar konusunda bilgilendirildim.
                        </p>
                        </div>
                        <div className="formItem col-12 pB20">
                            <label htmlFor="chkUyari">BY EXPRESS tarafından kişisel verilerin işlenmesi ve aktarılması süreçlerine ilişkin yöntem, amaç ve hukuki sebepler, aktarılan kişi grupları ile sahip olduğum haklar konusunda bilgilendirildim.</label>
                            <input type="checkbox" id="chkUyari" checked={this.state.Okudum} onChange={(e) => {
                                const target = e.target;
                                const value = target.type === 'checkbox' ? target.checked : target.value;
                                this.setState({
                                    Okudum: value
                                });
                            }}></input>
                        </div>
                        <div className="formItem col-6">
                            <label htmlFor="">&nbsp;</label>
                            <button className="basvuruFormuSend" onClick={
                                () => {
                                    if (!this.state.Okudum) {
                                        alert("BY EXPRESS tarafından kişisel verilerin işlenmesi ve aktarılması süreçlerine ilişkin yöntem, amaç ve hukuki sebepler, aktarılan kişi grupları ile sahip olduğum haklar konusunda bilgilendirildim. Onaylamalısınız ");
                                    } else {
                                        let data = {
                                            Ad: this.state.Ad,
                                            Soyad: this.state.Soyad,
                                            Email: this.state.Email,
                                            Gsm: this.state.Gsm,
                                            Telefon: this.state.Telefon,
                                            Adres: this.state.Adres,
                                            AdresTarifi: this.state.AdresTarifi,
                                            KapiNo: this.state.KapiNo,
                                            TcNo: this.state.TcNo,
                                            Ulke_Id: this.state.Ulke_Id,
                                            Il_Id: this.state.Il_Id,
                                            Ilce_Id: this.state.Ilce_Id
                                        }
                                        service.post(siteApi + "arayuz/postKuryeCagriForm"
                                            , data
                                            , null)
                                            .then(res => {
                                                if (res.data.Success) {
                                                    var ad = $('.basvuru-ad').val();
                                                    var soyad = $('.basvuru-soyad').val();
                                                    var email = $('.basvuru-email').val();
                                                    var il = $('.basvuru-il').val();
                                                    var ilce = $('.basvuru-ilce').val();
                                                    var gsm = $('.basvuru-gsm').val();
                                                    var telefon = $('.basvuru-telefon').val();
                                                    var adres = $('.basvuru-adres').val();
                                                    var adrestarifi = $('.basvuru-adrestarifi').val();
                                                    var kapino = $('.basvuru-kapino').val();
                                                    var tcno = $('.basvuru-tcno').val();
                                                    if (il === '') {
                                                        $('.basvuru-il').parent('div').addClass('warnInput');
                                                    } else {
                                                        $('.basvuru-il').parent('div').removeClass('warnInput');
                                                    }

                                                    if (ilce === '') {
                                                        $('.basvuru-ilce').parent('div').addClass('warnInput');
                                                    } else {
                                                        $('.basvuru-ilce').parent('div').removeClass('warnInput');
                                                    }

                                                    if (ad === '') {
                                                        $('.basvuru-ad').addClass('warnInput');
                                                        $('.basvuru-ad').attr('placeholder', 'Lütfen adınızı giriniz...');
                                                    } else {
                                                        $('.basvuru-ad').removeClass('warnInput');
                                                        $('.basvuru-ad').attr('placeholder', 'Adınız');
                                                    }

                                                    if (soyad === '') {
                                                        $('.basvuru-soyad').addClass('warnInput');
                                                        $('.basvuru-soyad').attr('placeholder', 'Lütfen soyadınızı giriniz...');
                                                    } else {
                                                        $('.basvuru-soyad').removeClass('warnInput');
                                                        $('.basvuru-soyad').attr('placeholder', 'Soyadınız');
                                                    }

                                                    if (email === '') {
                                                        $('.basvuru-email').addClass('warnInput');
                                                        $('.basvuru-email').attr('placeholder', 'Lütfen email adresi giriniz...');
                                                    } else {
                                                        $('.basvuru-email').removeClass('warnInput');
                                                        $('.basvuru-email').attr('placeholder', 'Email');
                                                    }

                                                    if (gsm === '') {
                                                        $('.basvuru-gsm').addClass('warnInput');
                                                        $('.basvuru-gsm').attr('placeholder', 'Lütfen gsm numaranızı giriniz...');
                                                    } else {
                                                        $('.basvuru-gsm').removeClass('warnInput');
                                                        $('.basvuru-gsm').attr('placeholder', 'Gsm');
                                                    }

                                                    if (telefon === '') {
                                                        $('.basvuru-telefon').addClass('warnInput');
                                                        $('.basvuru-telefon').attr('placeholder', 'Lütfen sabit telefon numaranızı giriniz...');
                                                    } else {
                                                        $('.basvuru-telefon').removeClass('warnInput');
                                                        $('.basvuru-telefon').attr('placeholder', 'Sabit telefonunuz');
                                                    }

                                                    if (adres === '') {
                                                        $('.basvuru-adres').addClass('warnInput');
                                                        $('.basvuru-adres').attr('placeholder', 'Lütfen adres giriniz...');
                                                    } else {
                                                        $('.basvuru-adres').removeClass('warnInput');
                                                        $('.basvuru-adres').attr('placeholder', 'Adres');
                                                    }

                                                    if (adrestarifi === '') {
                                                        $('.basvuru-adrestarifi').addClass('warnInput');
                                                        $('.basvuru-adrestarifi').attr('placeholder', 'Lütfen adres tarifi giriniz...');
                                                    } else {
                                                        $('.basvuru-adrestarifi').removeClass('warnInput');
                                                        $('.basvuru-adrestarifi').attr('placeholder', 'Adres tarifi');
                                                    }

                                                    if (tcno === '') {
                                                        $('.basvuru-tcno').addClass('warnInput');
                                                        $('.basvuru-tcno').attr('placeholder', 'Lütfen T.C. no giriniz...');
                                                    } else {
                                                        $('.basvuru-tcno').removeClass('warnInput');
                                                        $('.basvuru-tcno').attr('placeholder', 'T.C. no');
                                                    }
                                                    if (kapino === '') {
                                                        $('.basvuru-kapino').addClass('warnInput');
                                                        $('.basvuru-kapino').attr('placeholder', 'Lütfen kapı no giriniz...');
                                                    } else {
                                                        $('.basvuru-kapino').removeClass('warnInput');
                                                        $('.basvuru-kapino').attr('placeholder', 'Kapı no');
                                                    }

                                                    if (il !== '' && ilce !== '' && ad !== '' && soyad !== '' && email !== '' && telefon !== '' && gsm !== ''
                                                        && tcno !== ''
                                                        && kapino !== ''
                                                        && adres !== ''
                                                        && adrestarifi !== '') {
                                                        $('.basvuruForm .formContainer').html('<span class="success">Form başarıyla gönderildi <br/> Zaman ayırdığınız için teşekür ederiz</span>')
                                                    }
                                                    notify("Başvurunuz alındı", "success", 3000);
                                                } else {
                                                    notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 3000);
                                                }
                                            })
                                            .catch(error => {
                                                notify("Teknik bir hata oluştu, hata ayrıntısı:" + error, "error", 3000);
                                            })
                                    }

                                }
                            }>BAŞVUR</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}