import * as React from 'react';
export default class MenuBtn extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="menuBtn">
                    <span><i className="fas fa-bars"></i></span>
                </div>
            </React.Fragment>
        );
    }
}