import React from 'react';
export default class Slide extends React.Component {
    linkRender() {
        if (this.props.Url === null | this.props.Url === undefined)
            return <a href>DETAY</a>
        else
            return <a href={this.props.Url}>DETAY</a>
    }
    render() {
        return (
            <React.Fragment>
                <div className="slideItem container">
                    <div className="itemText">
                        <h2>{this.props.Baslik}</h2>
                        <p>{this.props.Aciklama}</p>
                        {this.linkRender()}
                    </div>
                    <div className="itemImage">
                        <img src={this.props.Resim} alt="" />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}