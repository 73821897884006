import * as React from "react";
import { YuklenenArkaPlanUrl } from '../../Utils';
export default class Banner extends React.Component {
    createLink() {
        if (this.props.AcilisSekli === 1) {
            return <a href={this.props.Url} target="_self" className="btnContinue" rel="noreferrer">DEVAM</a>
        }
        else if (this.props.AcilisSekli === 2) {
            return <a href={this.props.Url} target="_blank" className="btnContinue" rel="noreferrer">DEVAM</a>
        }
        else
            return <a href={"#" + this.props.Url} className="btnContinue" rel="noreferrer">DEVAM</a>
    }

    render() {
        if (this.props.Background === null | this.props.Background === undefined) {
            return (<React.Fragment>
               <section className="about-home">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                             <h3 className="title">{this.props.Baslik}</h3>
                             <span dangerouslySetInnerHTML={{ __html: this.props.Aciklama }}></span>
                            {this.createLink()}
                            </div>
                        </div>
                    </div>
               </section>
                <div style={{ minHeight: "10px" }}></div>
            </React.Fragment>);
        } else {
            return (<React.Fragment>
                <div className={"homeContentItem image-no-content bg-two"}
                    style={{
                        backgroundImage: "url(" + YuklenenArkaPlanUrl + this.props.Background + ")"
                    }}
                >
                    <div className="container">
                        <div className="contentText">
                            <h2>{this.props.Baslik}</h2>
                            <p>{this.props.Aciklama}</p>
                            {this.createLink()}
                        </div>
                    </div>
                </div>
                <div style={{ minHeight: "10px" }}></div>
            </React.Fragment>);
        }
    }
}