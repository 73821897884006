import * as React from 'react';
export default class Whatsapp extends React.Component {
    render() {
        return (
            <React.Fragment>
                <a target="_blank"
                    href={"https://api.whatsapp.com/send?phone=" + this.props.whatsapptel + "&amp;text=merhaba&amp;source=www.flypgs.com&amp;data="}
                    className="whatsapp ps-open" style={{
                        display: "flex"
                    }}
                    rel="noreferrer"
                >
                    <img src="/images/icon/whatsapp.png" alt="Whatsapp" />
                    {/* <span>Whatsapp İletişim Hattı</span> */}
                </a>
            </React.Fragment>
        );
    }
}