import * as React from 'react';
import { siteApi, YuklenenArkaPlanUrl } from '../../Utils';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import Logo from '../../components/Header/Logo';
import MenuBtn from '../../components/Header/MenuBtn';
import RightTop from '../../components/Header/RightTop';
import RightBottom from '../../components/Header/RightBottom';
import DetailHeader from '../../components/Header/DetailHeader';
import MainFooter from '../../components/Footer/MainFooter';
import Ust from '../../components/KuryeCagir/Ust';
import Form from '../../components/KuryeCagir/Form';
import Whatsapp from '../../components/Widgets/Whatsapp';
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet'
class KuryeCagir extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Sayfalar: [],
            FacebookAdresi: "",
            TwitterAdresi: "",
            YoutubeAdresi: "",
            LinkedinAdresi: "",
            InstagramAdresi: "",
            Telefon: "",
            TelefonBitisik: "",
            WhatsappTel: "",
            KuryeBaslik: "",
            KuryeAciklama: "",
            KuryeResim: ""
        }
        this.getData();
    }

    //#region form fonksiyonları

    async getData() {
        await this.getSetting()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await this.getSitePage()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

    }

    //#endregion

    //#region servis metodları

    async getSitePage() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getSiteSayfa", null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            Sayfalar: res.data.Data
                        });
                        resolve("Sayfa bilgileri getirildi.")
                    }
                    else
                        reject(res.data.ErrorMessage);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    async getSetting() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "arayuz/getSiteAyar", null)
                .then(res => {
                    if (res.data.Success) {
                        const ayar = res.data.Data[0];
                        this.setState({
                            FacebookAdresi: ayar.facebookadresi,
                            TwitterAdresi: ayar.twitteradresi,
                            YoutubeAdresi: ayar.youtubeadresi,
                            LinkedinAdresi: ayar.linkedinadresi,
                            InstagramAdresi: ayar.instagramadresi,
                            Telefon: ayar.telefon,
                            TelefonBitisik: ayar.telefon.trim(),
                            WhatsappTel: ayar.whatsapptel,
                            Adres: ayar.footeradres,
                            EmailAdresi: ayar.emailadresi,
                            KuryeBaslik: ayar.kuryebaslik,
                            KuryeAciklama: ayar.kuryeaciklama,
                            KuryeResim: ayar.kuryeresim,
                            SayfaIciLink: ayar.sayfaicilink
                        });
                        resolve("Ayar bilgisi getirildi...");
                    } else {
                        notify(res.data.ErrorMessage, "error", 3000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }


    //#endregion

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.state.KuryeBaslik}</title>
                    <meta name="description" content={this.state.KuryeAciklama.slice(0, 160)}></meta>
                </Helmet>
                <section id="detailPage">
                    <div className="headerContainer">
                        <div className="container d-flex align-items-end justify-content-between">
                            <Logo Logo="/images/logo/logo.png" Baslik="Byepxress"></Logo>
                            <div className="headerRight">
                                <MenuBtn />
                                <RightTop
                                    facebookadresi={this.state.FacebookAdresi}
                                    twitteradresi={this.state.TwitterAdresi}
                                    youtubeadresi={this.state.YoutubeAdresi}
                                    linkedinadresi={this.state.LinkedinAdresi}
                                    instagramadresi={this.state.InstagramAdresi}
                                    telefonbitisik={this.state.TelefonBitisik}
                                    telefon={this.state.Telefon}
                                    SayfaIciLink={this.state.SayfaIciLink} />
                                <RightBottom Sayfalar={this.state.Sayfalar} />
                            </div>
                        </div>
                    </div>



                    {/* detailheader başlangıç  */}
                    <DetailHeader Baslik={this.state.KuryeBaslik} />
                    {/* detailheader bitiş  */}

                    {/* detailcontainer başlangıç  */}
                    <div className="detailContainer">
                        <div className="container basvuru-page">
                            <Ust Aciklama={this.state.KuryeAciklama} Resim={YuklenenArkaPlanUrl + this.state.KuryeResim} />
                            <Form />
                        </div>
                    </div>
                    {/* detailcontainer bitiş  */}

                    {/* whatsapp başlangıç  */}
                    <Whatsapp whatsapptel={this.state.WhatsappTel}></Whatsapp>
                    {/* whatsapp bitiş  */}

                    {/* footer başlangıç  */}
                    <MainFooter
                        Menuler={this.state.Sayfalar}
                        FacebookAdresi={this.state.FacebookAdresi}
                        TwitterAdresi={this.state.TwitterAdresi}
                        YoutubeAdresi={this.state.YoutubeAdresi}
                        LinkedinAdresi={this.state.LinkedinAdresi}
                        InstagramAdresi={this.state.InstagramAdresi}
                        SayfaIciLink={this.state.SayfaIciLink} />
                    {/* footer bitiş  */}
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(KuryeCagir)