import * as React from 'react';
import { siteApi, firmaid } from '../../Utils';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import $ from 'jquery';
export default class FranchiseBasvuruFormu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Ad: "",
            Soyad: "",
            Tel1: "",
            Tel2: "",
            Email: "",
            IlIlce: "",
            ButceVeIs: "",
            Mesaj: ""
        }
    }
    render() {
        return (
            <React.Fragment>
                <br></br>
                <h2 className="content-title" style={{ fontWeight: "bold", fontFamily: "Gotham", fontSize: "24px" }}>Franchise Başvuru Formu</h2>
                <div className="formContainer d-flex flex-wrap franchiseForm">
                    <div className="formItem col-6">
                        <label htmlFor="">Adınız</label>
                        <input type="text" className="inputText basvuru-name" placeholder="Adınız" onChange={(e) => {
                            this.setState({
                                Ad: e.target.value
                            });
                        }} />
                    </div>
                    <div className="formItem col-6 pL50">
                        <label htmlFor="">Soyadınız</label>
                        <input type="text" className="inputText basvuru-surname" placeholder="Soyadınız" onChange={(e) => {
                            this.setState({
                                Soyad: e.target.value
                            });
                        }} />
                    </div>
                    <div className="formItem col-6">
                        <label htmlFor="">Telefon</label>
                        <div className="col-12 d-flex">
                            <div className="col-2"><input type="text" className="inputText basvuru-mobil1" maxLength={3} placeholder="111" onChange={(e) => {
                                this.setState({
                                    Tel1: e.target.value
                                });
                            }} /></div>
                            <div className="col-10 pL10"><input type="text" maxLength={10} className="inputText basvuru-mobil2" placeholder="111 11 11" onChange={(e) => {
                                this.setState({
                                    Tel2: e.target.value
                                });
                            }} /></div>
                        </div>
                    </div>
                    <div className="formItem col-6 pL50">
                        <label htmlFor="">E-posta Adresiniz</label>
                        <input type="text" className="inputText basvuru-eposta" placeholder="isim@firmaismi.com" onChange={(e) => {
                            this.setState({
                                Email: e.target.value
                            });
                        }} />
                    </div>
                    <div className="formItem col-12">
                        <label htmlFor="">İl/İlçe</label>
                        <input type="text" className="inputText contact-eposta" placeholder="Bulunduğunuz il ve ilçe" onChange={(e) => {
                            this.setState({
                                IlIlce: e.target.value
                            });
                        }} />
                    </div>
                    <div className="formItem col-12">
                        <label htmlFor="">Bütçeniz ve şu anki işiniz</label>
                        <textarea name="" id="" className="txtArea contact-mesaj" placeholder="Yatırım için ayrıdığınız bütçe ve şu anki işiniz" onChange={(e) => {
                            this.setState({
                                ButceVeIs: e.target.value
                            });
                        }}></textarea>
                    </div>
                    <div className="formItem col-12">
                        <label htmlFor="">Notunuz</label>
                        <textarea name="" id="" className="txtArea contact-mesaj" placeholder="Bize iletmek istediğiniz herhangi bir konu varsa lütfen belirtiniz." onChange={(e) => {
                            this.setState({
                                Mesaj: e.target.value
                            });
                        }}></textarea>
                    </div>
                    <div className="formItem col-12 pL50 form-btn-wrapper">
                        <button className="send-btn basvuruFormuSend" onClick={
                            () => {
                                let data = {
                                    Ad: this.state.Ad,
                                    Soyad: this.state.Soyad,
                                    Email: this.state.Email,
                                    Tel1: this.state.Tel1,
                                    Tel2: this.state.Tel2,
                                    Mesaj: this.state.Mesaj,
                                    IlIlce: this.state.IlIlce,
                                    ButceVeIs: this.state.ButceVeIs,
                                    Firma_Id: firmaid
                                }
                                service.post(siteApi + "sitearayuz/postFranchiseBasvuruForm"
                                    , data
                                    , null)
                                    .then(res => {
                                        if (res.data.Success) {
                                            var name = $('.basvuru-name').val();
                                            var surname = $('.basvuru-surname').val();
                                            var eposta = $('.basvuru-eposta').val();
                                            var mobil1 = $('.basvuru-mobil1').val();
                                            var mobil2 = $('.basvuru-mobil2').val();
                                            if (name === '') {
                                                $('.basvuru-name').addClass('warnInput');
                                                $('.basvuru-name').attr('placeholder', 'Lütfen adınızı giriniz...');
                                            } else {
                                                $('.basvuru-name').removeClass('warnInput');
                                                $('.basvuru-name').attr('placeholder', 'Adınız');
                                            }

                                            if (surname === '') {
                                                $('.basvuru-surname').addClass('warnInput');
                                                $('.basvuru-surname').attr('placeholder', 'Lütfen soyadınızı giriniz...');
                                            } else {
                                                $('.basvuru-surname').removeClass('warnInput');
                                                $('.basvuru-surname').attr('placeholder', 'Soyadınız');
                                            }

                                            if (eposta === '') {
                                                $('.basvuru-eposta').addClass('warnInput');
                                                $('.basvuru-eposta').attr('placeholder', 'Lütfen eposta adresi giriniz...');
                                            } else {
                                                $('.basvuru-eposta').removeClass('warnInput');
                                                $('.basvuru-eposta').attr('placeholder', 'Soyadınız');
                                            }

                                            if (mobil1 === '') {
                                                $('.basvuru-mobil1').addClass('warnInput');
                                                $('.basvuru-mobil1').attr('placeholder', 'Lütfen mobil numaranızı giriniz...');
                                            } else {
                                                $('.basvuru-mobil1').removeClass('warnInput');
                                                $('.basvuru-mobil1').attr('placeholder', 'Soyadınız');
                                            }

                                            if (mobil2 === '') {
                                                $('.basvuru-mobil2').addClass('warnInput');
                                                $('.basvuru-mobil2').attr('placeholder', 'Lütfen mobil numaranızı giriniz...');
                                            } else {
                                                $('.basvuru-mobil2').removeClass('warnInput');
                                                $('.basvuru-mobil2').attr('placeholder', 'Soyadınız');
                                            }
                                            notify("Başvurunuz alındı", "success", 3000);
                                        } else {
                                            notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 3000);
                                        }
                                    })
                                    .catch(error => {
                                        notify("Teknik bir hata oluştu, hata ayrıntısı:" + error, "error", 3000);
                                    })
                            }
                        }>BAŞVUR</button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}