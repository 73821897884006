import * as React from 'react';
export default class Ust extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="detailHTML">
                    <p>{this.props.Aciklama}</p>
                    <p></p>
                    <p><img src={this.props.Resim} className="imgFull" alt="" /></p>
                </div>
            </React.Fragment>
        );
    }
}