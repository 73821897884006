import * as React from 'react';
import FranchiseBasvuruFormu from '../Forms/FranchiseBasvuruFormu'
import BayiBasvuruFormu from '../../components/BasvuruFormu/Form';
import InsanKaynaklariFormu from '../../components/Forms/InsanKaynaklariForm';
import IletisimFormu from '../../components/Forms/ContactForm';
export default class DetailRightCol extends React.Component {
    formWidgetCreate(widgetType) {
        if (widgetType === 0) {
            return "";
        }
        else if (widgetType === 1) {
            return <BayiBasvuruFormu />;
        }
        else if (widgetType === 2) {
            return <InsanKaynaklariFormu />;
        }
        else if (widgetType === 3) {
            return <FranchiseBasvuruFormu />;
        }
        else if (widgetType === 4) {
            return <IletisimFormu />;
        }
        else {
            return "";
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="detailRightCol">
                    <div className="detailHTML" dangerouslySetInnerHTML={{ __html: this.props.Detay }}>

                    </div>
                    {this.formWidgetCreate(this.props.FormWidget)}
                </div>
            </React.Fragment>
        );
    }
}