import * as React from 'react'
export default class MapContainer extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="mapContainer">
                    <iframe
                        title="IletisimHaritasi"
                        src={this.props.Koordinat}
                        style={{ width: "100%", height: "480px", frameborder: "0", border: "0" }}
                        allowFullScreen="" aria-hidden="false" tabIndex="0">
                    </iframe>
                </div>
            </React.Fragment>
        );
    }
}