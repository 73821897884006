import * as React from "react";
export default class Logo extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="logo">
                    <a href="/"><img src={this.props.Logo} alt={this.props.Baslik} /></a>
                </div>
            </React.Fragment>)
            ;
    }
}