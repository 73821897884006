import * as React from "react";
import { YuklenenIconUrl, YuklenenArkaPlanUrl } from '../../Utils';
export default class DortluBolme extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="homeWhyByExpress" style={
                    {
                        backgroundImage: "url(" + YuklenenArkaPlanUrl + this.props.Background + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundPositionX: "center",
                        backgroundPositionY: "center"
                    }}>
                    <div className="container">
                        <h2 className="title">{this.props.Baslik}</h2>
                        <div className="whyByExpressItems d-flex">
                            <div className="item col-3">
                                <div className="icon"><img src={YuklenenIconUrl + this.props.KutuBirIkon} alt="" /></div>
                                <div className="itemText">
                                    <h2>{this.props.KutuBirBaslik}</h2>
                                    <p>{this.props.KutuBirIcerik}</p>
                                </div>
                            </div>
                            <div className="item col-3">
                                <div className="icon"><img src={YuklenenIconUrl + this.props.KutuIkiIkon} alt="" /></div>
                                <div className="itemText">
                                    <h2>{this.props.KutuIkiBaslik}</h2>
                                    <p>{this.props.KutuIkiIcerik}</p>
                                </div>
                            </div>
                            <div className="item col-3">
                                <div className="icon"><img src={YuklenenIconUrl + this.props.KutuUcIkon} alt="" /></div>
                                <div className="itemText">
                                    <h2>{this.props.KutuUcBaslik}</h2>
                                    <p>{this.props.KutuUcIcerik}</p>
                                </div>
                            </div>
                            <div className="item col-3">
                                <div className="icon"><img src={YuklenenIconUrl + this.props.KutuDortIkon} alt="" /></div>
                                <div className="itemText">
                                    <h2>{this.props.KutuDortBaslik}</h2>
                                    <p>{this.props.KutuDortIcerik}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>)
            ;
    }
}