import * as React from "react";
import { YuklenenIconUrl, YuklenenArkaPlanUrl } from '../../Utils';
export default class TekliBolme extends React.Component {
    createLink() {
        if (this.props.AcilisSekli === 1) {
            return <a href={this.props.Url} target="_self" className="btnContinue" rel="noreferrer">DEVAM</a>
        }
        else if (this.props.AcilisSekli === 2) {
            return <a href={this.props.Url} target="_blank" className="btnContinue" rel="noreferrer">DEVAM</a>
        }
        else
            return <a href={"#" + this.props.Url} className="btnContinue" rel="noreferrer">DEVAM</a>
    }
    render() {
        return (
            <React.Fragment>
                <div className={this.props.ResimSagaYasla === true ? "homeContentItem image-right-content bg-one" : "homeContentItem image-left-content bg-one"}
                    style={{
                        backgroundImage: "url(" + YuklenenArkaPlanUrl + this.props.Background + ")"
                    }}
                >
                    <div className="container d-flex align-items-center justify-content-between">
                        <div className="image">
                            <img src={YuklenenIconUrl + this.props.Resim} alt="" />
                        </div>
                        <div className="contentText">
                            <h2>{this.props.Baslik}</h2>
                            <p>{this.props.Aciklama}</p>
                            {this.createLink()}
                        </div>
                    </div>
                </div>
                <div style={{ minHeight: "85px" }}></div>
            </React.Fragment>)
            ;
    }
}