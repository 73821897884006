import * as React from "react";
import { firmaid, siteApi, YuklenenLogoUrl } from '../Utils';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog';
import Logo from '../components/Header/Logo';
import MenuBtn from '../components/Header/MenuBtn';
import RightTop from '../components/Header/RightTop';
import RightBottom from '../components/Header/RightBottom';
import MainFooter from '../components/Footer/MainFooter';
import Whatsapp from '../components/Widgets/Whatsapp';
import { withRouter } from "react-router";

class PasswordRefreshPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            EmailAdresi: "",
            Telefon: "",
            TelefonBitisik: "",
            SiteBaslik: "",
            FacebookAdresi: "",
            YoutubeAdresi: "",
            TwitterAdresi: "",
            InstagramAdresi: "",
            LinkedinAdresi: "",
            DribbleAdresi: "",
            FooterAdres: "",
            FooterCopyright: "",
            FooterArkaPlan: "",
            SayfaIciLink: false,
            Koordinat: "",
            WhatsappTel: "",
            Logo: "",
            EbultenMail: null,
            Slaytlar: [],
            Sayfalar: [],
            DortluBolmeler: [],
            TekliBolmeler: [],
            UcluHaberler: [],
            Bannerlar: [],
            Bolmeler: [],
            Subeler: [],
            FormAdSoyad: "",
            FormEmail: "",
            FormTelefon: "",
            FormMesaj: "",
            EkranBaslik: "",
            VideoKaynak: "",
            VideoKaynakLocal: "",
            IsVideo: false,
            LocalVideo: false,
            Resim: "",
            PopupId: 0,
            popup: false
        };
        this.formData = {
            EmailGuid:this.props.match.params.guid,
            Sifre: "",
            Sifre2: "",
       }

    }

    //#region form fonksiyonları

    popupGizle() {
        this.setState({
            popup: false
        });
    }

    async getData() {
        await this.getSetting()
            .then(res => {


            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await this.getSitePage()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

      




    }

    //#endregion

    //#region ui fonksiyonları

    //#endregion

    //#region servis metodları

    async getSitePage() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getSiteSayfa/"+firmaid, null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            Sayfalar: res.data.Data
                        });
                        resolve("Sayfa bilgileri getirildi.")
                    }
                    else
                        reject(res.data.ErrorMessage);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    async getSetting() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getSiteAyar/"+firmaid, null)
                .then(res => {
                    if (res.data.Success) {
                        const ayar = res.data.Data[0];
                        this.setState({
                            FacebookAdresi: ayar.facebookadresi,
                            TwitterAdresi: ayar.twitteradresi,
                            YoutubeAdresi: ayar.youtubeadresi,
                            LinkedinAdresi: ayar.linkedinadresi,
                            InstagramAdresi: ayar.instagramadresi,
                            WhatsappTel: ayar.whatsapptel,
                            Telefon: ayar.telefon,
                            SayfaIciLink: ayar.sayfaicilink,
                            SiteBaslik:ayar.sitebaslik,
                            Logo:ayar.logo,
                            TelefonBitisik: ayar.telefon.trim(),
                            popup: (ayar.popupgoster===null | ayar.popupgoster===undefined)?false:ayar.popupgoster
                        });
                        
                        resolve("Ayar bilgisi getirildi...");
                    } else {
                        notify(res.data.ErrorMessage, "error", 3000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    //#endregion

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <React.Fragment>
                <section id="homePage">
                    <div className="headerContainer">
                        <div className="container d-flex align-items-end justify-content-between">
                            <Logo Logo={YuklenenLogoUrl+this.state.Logo} Baslik={this.state.SiteBaslik}></Logo>
                            <div className="headerRight">
                                <MenuBtn />
                                <RightTop
                                    facebookadresi={this.state.FacebookAdresi}
                                    twitteradresi={this.state.TwitterAdresi}
                                    youtubeadresi={this.state.YoutubeAdresi}
                                    linkedinadresi={this.state.LinkedinAdresi}
                                    instagramadresi={this.state.InstagramAdresi}
                                    telefonbitisik={this.state.TelefonBitisik}
                                    telefon={this.state.Telefon}
                                    SayfaIciLink={this.state.SayfaIciLink} />
                                <RightBottom Sayfalar={this.state.Sayfalar} />
                            </div>
                        </div>
                    </div>
                    <div className="detailHeader bgHeader">
                            <div className="container">
                            <h1>Şifre Yenileme</h1>
                            </div>
                    </div>
                    <div classNames="passwordrenewal-container">
                    <div className="container col-6">
                    <div className="password-form pT40">
                        <div className="formContainer col-12 mT30">
                        <div className="formItem col-6">
                            <label htmlFor="">Yeni Şifreniz</label>
                                        <input type="password"
                                            className="inputText password"
                                            onChange={(e) => {
                                                this.formData.Sifre = e.target.value;
                                            }}
                                            placeholder="Yeni Şifreniz" />
                        </div>
                        <div className="formItem col-6">
                            <label htmlFor="">Yeni Şifreniz Tekrar</label>
                                        <input type="password"
                                            className="inputText password"
                                            onChange={(e) => {
                                                this.formData.Sifre2 = e.target.value;
                                            }}
                                            placeholder="Yeni Şifreniz Tekrar" />
                        </div>
                        <div className="formItem col-6">
                        <br></br>                
                       </div>
                        <div className="formItem col-12 pB20 align-items-center">
                                        <button className="col-6 passwordRenewal-btn"
                                        onClick={() => {
                                                service.post(siteApi + "sitearayuz/SifreYenile"
                                                , this.formData
                                                    , null)
                                                    .then(res => {
                                                        if (res.data.Success) {
                                                            alert("Şifreniz yenilendi","Bilgi");
                                                        } else {
                                                     notify("Teknik bir hata oluştu,hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);       
                                                        }
                                                    })
                                                 .catch(error => {
                                                     notify("Teknik bir hata oluştu,hata ayrıntısı:" + error, "error", 5000);
                                                });
                                            }}
                                        >GÖNDER</button>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                   {/* whatsapp başlangıç  */}
                    <Whatsapp whatsapptel={this.state.WhatsappTel}></Whatsapp>
                    {/* whatsapp bitiş  */}

                    {/* footer başlangıç  */}
                    <MainFooter
                        Menuler={this.state.Sayfalar}
                        Subeler={this.state.Subeler}
                        FacebookAdresi={this.state.FacebookAdresi}
                        TwitterAdresi={this.state.TwitterAdresi}
                        YoutubeAdresi={this.state.YoutubeAdresi}
                        LinkedinAdresi={this.state.LinkedinAdresi}
                        InstagramAdresi={this.state.InstagramAdresi}
                        SayfaIciLink={this.state.SayfaIciLink} />
                    {/* footer bitiş  */}
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(PasswordRefreshPage);