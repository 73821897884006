import * as React from "react";
import { firmaid, siteApi, YuklenenLogoUrl } from '../Utils';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog';
import Logo from '../components/Header/Logo';
import MenuBtn from '../components/Header/MenuBtn';
import RightTop from '../components/Header/RightTop';
import RightBottom from '../components/Header/RightBottom';
import MainFooter from '../components/Footer/MainFooter';
import Whatsapp from '../components/Widgets/Whatsapp';
import { withRouter } from "react-router";

class RegisterPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            EmailAdresi: "",
            Telefon: "",
            TelefonBitisik: "",
            SiteBaslik: "",
            FacebookAdresi: "",
            YoutubeAdresi: "",
            TwitterAdresi: "",
            InstagramAdresi: "",
            LinkedinAdresi: "",
            DribbleAdresi: "",
            FooterAdres: "",
            FooterCopyright: "",
            FooterArkaPlan: "",
            SayfaIciLink: false,
            Koordinat: "",
            WhatsappTel: "",
            Logo: "",
            EbultenMail: null,
            Slaytlar: [],
            Sayfalar: [],
            DortluBolmeler: [],
            TekliBolmeler: [],
            UcluHaberler: [],
            Bannerlar: [],
            Bolmeler: [],
            Subeler: [],
            FormAdSoyad: "",
            FormEmail: "",
            FormTelefon: "",
            FormMesaj: "",
            EkranBaslik: "",
            VideoKaynak: "",
            VideoKaynakLocal: "",
            IsVideo: false,
            LocalVideo: false,
            Resim: "",
            PopupId: 0,
            popup: false
        };
        this.formData = {
            firmaid:firmaid,
            AdSoyad: "",
            Email: "",
            Telefon1: "",
            Telefon2:"",
            Password: "",
            Password2:"",
            Bloke: false,
            Mailbilgi: false,
            SmsBilgi: false,
            EmailVerified:true
       }

    }

    //#region form fonksiyonları

    popupGizle() {
        this.setState({
            popup: false
        });
    }

    async getData() {
        await this.getSetting()
            .then(res => {


            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await this.getSitePage()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

      




    }

    //#endregion

    //#region ui fonksiyonları

    //#endregion

    //#region servis metodları

    async getSitePage() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getSiteSayfa/"+firmaid, null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            Sayfalar: res.data.Data
                        });
                        resolve("Sayfa bilgileri getirildi.")
                    }
                    else
                        reject(res.data.ErrorMessage);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    async getSetting() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getSiteAyar/"+firmaid, null)
                .then(res => {
                    if (res.data.Success) {
                        const ayar = res.data.Data[0];
                        this.setState({
                            FacebookAdresi: ayar.facebookadresi,
                            TwitterAdresi: ayar.twitteradresi,
                            YoutubeAdresi: ayar.youtubeadresi,
                            LinkedinAdresi: ayar.linkedinadresi,
                            InstagramAdresi: ayar.instagramadresi,
                            WhatsappTel: ayar.whatsapptel,
                            Telefon: ayar.telefon,
                            SayfaIciLink: ayar.sayfaicilink,
                            SiteBaslik:ayar.sitebaslik,
                            Logo:ayar.logo,
                            TelefonBitisik: ayar.telefon.trim(),
                            popup: (ayar.popupgoster===null | ayar.popupgoster===undefined)?false:ayar.popupgoster
                        });
                        
                        resolve("Ayar bilgisi getirildi...");
                    } else {
                        notify(res.data.ErrorMessage, "error", 3000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    //#endregion

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <React.Fragment>
                <section id="homePage">
                    <div className="headerContainer">
                        <div className="container d-flex align-items-end justify-content-between">
                            <Logo Logo={YuklenenLogoUrl+this.state.Logo} Baslik={this.state.SiteBaslik}></Logo>
                            <div className="headerRight">
                                <MenuBtn />
                                <RightTop
                                    facebookadresi={this.state.FacebookAdresi}
                                    twitteradresi={this.state.TwitterAdresi}
                                    youtubeadresi={this.state.YoutubeAdresi}
                                    linkedinadresi={this.state.LinkedinAdresi}
                                    instagramadresi={this.state.InstagramAdresi}
                                    telefonbitisik={this.state.TelefonBitisik}
                                    telefon={this.state.Telefon}
                                    SayfaIciLink={this.state.SayfaIciLink} />
                                <RightBottom Sayfalar={this.state.Sayfalar} />
                            </div>
                        </div>
                    </div>
                    <div class="detailHeader bgHeader">
                            <div class="container">
                            <h1>Yeni Üyelik</h1>
                            </div>
                        </div>
                    <div class="register-container">
                         <div class="container col-6">
                             <div class="register-form pT40">
                                 <h2 class="text-center title">Yeni Üyelik Formu</h2>
                                  <div class="formContainer col-12 mT30">
                                     <div class="formItem col-6 pB20">
                                        <label for="">Adınız Soyadınız</label>
                                        <input type="text"
                                            class="inputText register-namesurname"
                                            placeholder="Adınız Soyadınız"
                                            onChange={(e) => {
                                                this.formData.AdSoyad = e.target.value;
                                            }}
                                        />
                                    </div>
                                     <div class="formItem col-6 pB20">
                                        <label for="">Telefon Numaranız</label>
                                        <div class="col-12 d-flex">
                                            <div class="col-2"><input type="text"
                                                class="inputText register-mobil1"
                                                placeholder="111"
                                                onChange={(e) => {
                                                    this.formData.Telefon1 = e.target.value;
                                                }}
                                            /></div>
                                            <div class="col-10 pL10"><input type="text"
                                                class="inputText register-mobil2"
                                                placeholder="1111111"
                                                onChange={(e) => {
                                                    this.formData.Telefon2 = e.target.value;
                                                }}
                                            /></div>
                                        </div>
                                    </div>
                                     <div class="formItem col-6 pB20">
                                        <label for="">E-posta Adresiniz</label>
                                        <input type="email" class="inputText register-eposta"
                                            placeholder="isim@firmaismi.com"
                                             onChange={(e) => {
                                                    this.formData.Email = e.target.value;
                                                }}
                                        />
                                    </div>
                                     <div class="formItem col-6 pB20">
                                        <label for="password">Şifre</label>
                                        <input type="password"
                                            class="inputText password"
                                             onChange={(e) => {
                                                    this.formData.Password = e.target.value;
                                                }}
                                        />
                                    </div>
                                     <div class="formItem col-6 pB20">
                                        <label for="re-password">Şifre Tekrar</label>
                                        <input type="password"
                                            class="inputText re-password"
                                             onChange={(e) => {
                                                    this.formData.Password2 = e.target.value;
                                                }}
                                        />
                                    </div>
                                     <div class="formItem col-6 pB20">
                                        <a class="forget-password" href="/sifregonder">Şifremi unuttum!</a>
                                    </div>
                                     <div class="formItem col-12 pB20 align-items-center">
                                        <button
                                            class="col-6 registerform-btn"
                                            onClick={() => {
                                                service.post(siteApi + "sitearayuz/register"
                                                , this.formData
                                                    , null)
                                                    .then(res => {
                                                        if (res.data.Success) {
                                                            alert("Kayıt işleminiz tamamlandı","Bilgi");
                                                        } else {
                                                     notify("Teknik bir hata oluştu,hata ayrıntısı:" + res.data.ErrorMessage, "error", 5000);       
                                                        }
                                                    })
                                                 .catch(error => {
                                                     notify("Teknik bir hata oluştu,hata ayrıntısı:" + error, "error", 5000);
                                                });
                                            }}
                                        >GÖNDER</button>
                                    </div>
                                  </div>
                             </div>
                          </div>
                     </div>
                    {/* whatsapp başlangıç  */}
                    <Whatsapp whatsapptel={this.state.WhatsappTel}></Whatsapp>
                    {/* whatsapp bitiş  */}

                    {/* footer başlangıç  */}
                    <MainFooter
                        Menuler={this.state.Sayfalar}
                        Subeler={this.state.Subeler}
                        FacebookAdresi={this.state.FacebookAdresi}
                        TwitterAdresi={this.state.TwitterAdresi}
                        YoutubeAdresi={this.state.YoutubeAdresi}
                        LinkedinAdresi={this.state.LinkedinAdresi}
                        InstagramAdresi={this.state.InstagramAdresi}
                        SayfaIciLink={this.state.SayfaIciLink} />
                    {/* footer bitiş  */}
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(RegisterPage);