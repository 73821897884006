import * as React from "react";
export default class KargoTakipForm extends React.Component {
    constructor(props) {
        super(props);
        this.kargoTakipNo = "";
    }
    render() {
        return (
            <React.Fragment>
                <div className="searchCargo">
                    <div className="homeCargoForm">
                        <input type="text" className="inputTxt sourceSans" placeholder="Kargo takip numarası giriniz" onChange={(e) => {
                            this.kargoTakipNo = e.target.value;
                        }}
                            onKeyDownCapture={
                                (e) => {
                                    if (e.keyCode === 13) {
                                        if (this.kargoTakipNo === "") {
                                            alert("Lütfen kargo takip numaranızı giriniz");
                                        }
                                        else {
                                            window.location.href = "/kargotakip/" + this.kargoTakipNo;
                                        }
                                    }
                                }
                            }
                        />
                        <button
                            onClick={() => {
                                if (this.kargoTakipNo === "") {
                                    alert("Lütfen kargo takip numaranızı giriniz");
                                }
                                else {
                                    window.location.href = "/kargotakip/" + this.kargoTakipNo;
                                }
                            }}
                        ></button>
                    </div>
                </div>
            </React.Fragment>)
            ;
    }
}