import * as React from 'react';
import { Helmet } from 'react-helmet';
export default class InsanKaynaklariInfo extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.props.Baslik}</title>
                    <meta name="description" content={this.props.Aciklama.slice(0, 160)}></meta>
                </Helmet>
                <div className="detailHTML">
                    <p>{this.props.Aciklama}</p>
                    <p>&nbsp;</p>
                    <p><img src={this.props.Resim} className="imgFull" alt="" /></p>
                </div>
            </React.Fragment>
        );
    }
}