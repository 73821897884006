import * as React from "react";
import { YuklenenHaberResimUrl } from '../../Utils';
export default class HaberKutusu extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className={"item col-4"}>
                    <div className="image">
                        <img src={this.props.Resim === null | this.props.Resim === undefined | this.props.Resim === "" ? "/images/haberler_joker_gorsel.jpg" : YuklenenHaberResimUrl + this.props.Resim} alt="" />
                    </div>
                    <div className="itemText">
                        <h2>{this.props.Baslik}</h2>
                        <p>{this.props.KisaAciklama}</p>
                        <a href={this.props.Url} rel="noreferrer"><img src="images/icon/news-button.png" alt="" /></a>
                    </div>
                </div>

            </React.Fragment>)
            ;
    }
}