import * as React from 'react';
import { firmaid, siteApi, YuklenenLogoUrl } from '../../Utils';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import Logo from '../../components/Header/Logo';
import MenuBtn from '../../components/Header/MenuBtn';
import RightTop from '../../components/Header/RightTop';
import RightBottom from '../../components/Header/RightBottom';
import DetailHeader from '../../components/Header/DetailHeader';
import MainFooter from '../../components/Footer/MainFooter';
import HaberKutusu from '../../components/Widgets/HaberKutusu';
// import Whatsapp from '../../components/Widgets/Whatsapp';
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router";

class Haberler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Sayfalar: [],
            Haberler: [],
            FacebookAdresi: "",
            TwitterAdresi: "",
            YoutubeAdresi: "",
            LinkedinAdresi: "",
            InstagramAdresi: "",
            Telefon: "",
            WhatsappTel: "",
            TelefonBitisik: ""
        }
        this.getData();
    }

    //#region form fonksiyonları

    async getData() {
        await this.getSetting()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await this.getSitePage()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await this.getHaberler()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });
    }

    //#endregion

    //#region servis metodları

    async getSitePage() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getSiteSayfa/" + firmaid, null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            Sayfalar: res.data.Data
                        });
                        resolve("Sayfa bilgileri getirildi.")
                    }
                    else
                        reject(res.data.ErrorMessage);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    async getSetting() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getSiteAyar/" + firmaid, null)
                .then(res => {
                    if (res.data.Success) {
                        const ayar = res.data.Data[0];
                        this.setState({
                            FacebookAdresi: ayar.facebookadresi,
                            TwitterAdresi: ayar.twitteradresi,
                            YoutubeAdresi: ayar.youtubeadresi,
                            LinkedinAdresi: ayar.linkedinadresi,
                            InstagramAdresi: ayar.instagramadresi,
                            Telefon: ayar.telefon,
                            SiteBaslik: ayar.sitebaslik,
                            Logo: ayar.logo,
                            WhatsappTel: ayar.whatsapptel,
                            TelefonBitisik: ayar.telefon.trim(),
                            Adres: ayar.footeradres,
                            EmailAdresi: ayar.emailadresi,
                            SayfaIciLink: ayar.sayfaicilink
                        });
                        resolve("Ayar bilgisi getirildi...");
                    } else {
                        notify(res.data.ErrorMessage, "error", 3000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    async getHaberler() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getHaberler/" + firmaid, null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            Haberler: res.data.Data
                        });
                        resolve("Haber bilgileri getirildi...");
                    } else {
                        notify(res.data.ErrorMessage, "error", 3000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }
    //#endregion

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{window.ayarlar.haberlerTitle}</title>
                    <meta name="description" content={window.ayarlar.haberlerAciklama.slice(0, 160)}>

                    </meta>
                </Helmet>
                <section id="detailPage">
                    <div className="headerContainer">
                        <div className="container d-flex align-items-end justify-content-between">
                            <Logo Logo={YuklenenLogoUrl + this.state.Logo} Baslik={this.state.SiteBaslik}></Logo>
                            <div className="headerRight">
                                <MenuBtn />
                                <RightTop
                                    facebookadresi={this.state.FacebookAdresi}
                                    twitteradresi={this.state.TwitterAdresi}
                                    youtubeadresi={this.state.YoutubeAdresi}
                                    linkedinadresi={this.state.LinkedinAdresi}
                                    instagramadresi={this.state.InstagramAdresi}
                                    telefonbitisik={this.state.TelefonBitisik}
                                    telefon={this.state.Telefon}
                                    SayfaIciLink={this.state.SayfaIciLink} />
                                <RightBottom Sayfalar={this.state.Sayfalar} PropDetay={this.props} />
                            </div>
                        </div>
                    </div>

                    {/* detailheader başlangıç  */}
                    <DetailHeader Baslik="Haberler" />
                    {/* detailheader bitiş  */}

                    {/* detailcontainer başlangıç  */}
                    <div className="detailContainer">
                        <div className="container col-two d-flex align-items-start justify-content-between">
                            <div className="newsListContainer">
                                <div className="newsItems d-flex flex-wrap">
                                    {this.state.Haberler
                                        .sort((a, b) => (a.id > b.id ? 1 : -1))
                                        .map((haber, i) => {
                                            return <HaberKutusu
                                                key={i}
                                                Baslik={haber.baslik}
                                                Resim={haber.resim}
                                                Url={"/haberler/haberdetay/" + haber.id}
                                                KisaAciklama={haber.kisaaciklama} />
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* detailcontainer bitiş  */}

                    {/* whatsapp başlangıç  */}
                    {/* <Whatsapp whatsapptel={this.state.WhatsappTel}></Whatsapp> */}
                    {/* whatsapp bitiş  */}

                    {/* footer başlangıç  */}
                    <MainFooter
                        Menuler={this.state.Sayfalar}
                        FacebookAdresi={this.state.FacebookAdresi}
                        TwitterAdresi={this.state.TwitterAdresi}
                        YoutubeAdresi={this.state.YoutubeAdresi}
                        LinkedinAdresi={this.state.LinkedinAdresi}
                        InstagramAdresi={this.state.InstagramAdresi}
                        SayfaIciLink={this.state.SayfaIciLink} />
                    {/* footer bitiş  */}
                </section>
            </React.Fragment>
        );
    }
}

export default withRouter(Haberler)