import * as React from 'react';
import { siteApi,firmaid } from '../../Utils';
import service from 'axios';
import $ from 'jquery';
export default class EbultenFormu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ebulten: ""
        };
        this.handleValueChanged = this.handleValueChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleValueChanged(e) {
        this.setState({
            ebulten: e.target.value
        })
    }

    handleSubmit() {
        if (this.state.ebulten === "" | this.state.ebulten === undefined | this.state.ebulten === null)
            return;
        else {
            var data = {
                Email: this.state.ebulten,
                Aktif: true,
                Firma:{Id:firmaid}
            };
            service.post(siteApi + 'sitearayuz/postEbulten',
                data)
                .then(res => {
                    if (res.data.Success) {
                        $('.formDiv').hide();
                        $('.footerForm').html('<span>Başvurunuz alındı.</span>');
                    } else {
                        $('.formDiv').hide();
                        $('.footerForm').html('<span>Başvurunuz alınamadı.<br/>' + res.data.ErrorMessage + '</span>');
                    }
                })
                .catch(error => {
                    $('.formDiv').hide();
                    $('.footerForm').html('<span>Başvurunuz alınamadı.<br/>' + error + '</span>');
                })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="footerMenu">
                    <h2>SİZE ULAŞALIM</h2>
                    <div className="footerForm">
                        <input type="text" className="inputTxt footerFormInput sourceSans" placeholder="E-mail adresiniz" value={this.state.ebulten} onChange={this.handleValueChanged} />
                        <button className="btnSend footerFormSend" onClick={this.handleSubmit}></button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}