import * as React from "react";
import HaberKutusu from './HaberKutusu';
export default class UcluHaberKutusu extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="homeNewsContainer">
                    <div className="container">
                        <h2 className="title">Haberler</h2>
                        <div className="newsItems d-flex">
                            {this.props.UcluHaberler.sort(x => x.id)
                                .slice(0, 3)
                                .map((haber, i) => {
                                    return <HaberKutusu
                                        key={i}
                                        Baslik={haber.baslik}
                                        Resim={haber.resim}
                                        KisaAciklama={haber.kisaaciklama}
                                        Url={"/haberler/haberdetay/" + haber.id} />

                                })}
                        </div>
                    </div>
                </div>
                <div style={{ minHeight: "20px" }}></div>
            </React.Fragment>)
            ;
    }
}