export const authApi = window.ayarlar.devType === "developer" ? window.ayarlar.localAuth : window.ayarlar.productAuth;// "http://localhost/QrPosGateway/auth/";
export const coreApi = window.ayarlar.devType === "developer" ? window.ayarlar.localCore : window.ayarlar.productCore;//"http://localhost/QrPosGateway/core/";
export const siteApi = window.ayarlar.devType === "developer" ? window.ayarlar.localSite : window.ayarlar.productSite;//"http://localhost/QrPosGateway/site/";
export const YuklenenSlaytUrl = window.ayarlar.devType === "developer" ? window.ayarlar.localYuklenenSlaytUrl : window.ayarlar.productYuklenenSlaytUrl;//"http://localhost/QrPosSite/StaticFiles/YuklenenSlaytlar/";
export const YuklenenArkaPlanUrl = window.ayarlar.devType === "developer" ? window.ayarlar.localYuklenenArkaPlanUrl : window.ayarlar.productYuklenenArkaPlanUrl;//"http://localhost/QrPosSite/StaticFiles/YuklenenArkaPlanlar/";
export const YuklenenLogoUrl = window.ayarlar.devType === "developer" ? window.ayarlar.localYuklenenLogoUrl : window.ayarlar.productYuklenenLogoUrl;//"http://localhost/QrPosSite/StaticFiles/YuklenenLogolar/";
export const YuklenenIconUrl = window.ayarlar.devType === "developer" ? window.ayarlar.localYuklenenIconlar : window.ayarlar.productYuklenenIconlar;
export const YuklenenHaberResimUrl = window.ayarlar.devType === "developer" ? window.ayarlar.localYuklenenHaberResimUrl : window.ayarlar.productYuklenenHaberResimUrl;
export const YuklenenFormUrl = window.ayarlar.devType === "developer" ? window.ayarlar.localYuklenenFormUrl : window.ayarlar.productYuklenenFormUrl;
export const YuklenenVideoUrl = window.ayarlar.devType === "developer" ? window.ayarlar.localYuklenenVideoUrl : window.ayarlar.productYuklenenVideoUrl;
export const kargoTakipUrl = window.ayarlar.devType === "developer" ? window.ayarlar.demoKargoTakipApiUrl : window.ayarlar.canliKargoTakipApiUrl;
export const urunUrl = window.ayarlar.devType === "developer" ? window.ayarlar.localUrunUrl : window.ayarlar.productUrunUrl;//"http://localhost/QrPosSite/StaticFiles/";
export const firmaid = window.ayarlar.firmaid;
