import React from 'react';
import { firmaid, siteApi, YuklenenLogoUrl, urunUrl } from '../../Utils';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import Logo from '../../components/Header/Logo';
import MenuBtn from '../../components/Header/MenuBtn';
import RightTop from '../../components/Header/RightTop';
import RightBottom from '../../components/Header/RightBottom';
import DetailHeader from '../../components/Header/DetailHeader';
import MainFooter from '../../components/Footer/MainFooter';
// import Whatsapp from '../../components/Widgets/Whatsapp';
import { withRouter } from "react-router";
class QrMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            EmailAdresi: "",
            Telefon: "",
            TelefonBitisik: "",
            SiteBaslik: "",
            FacebookAdresi: "",
            YoutubeAdresi: "",
            TwitterAdresi: "",
            InstagramAdresi: "",
            LinkedinAdresi: "",
            DribbleAdresi: "",
            FooterAdres: "",
            FooterCopyright: "",
            FooterArkaPlan: "",
            SayfaIciLink: false,
            Koordinat: "",
            WhatsappTel: "",
            Logo: "",
            EbultenMail: null,
            Sayfalar: [],
            FormAdSoyad: "",
            FormEmail: "",
            FormTelefon: "",
            FormMesaj: "",
            EkranBaslik: "",
            VideoKaynak: "",
            VideoKaynakLocal: "",
            IsVideo: false,
            LocalVideo: false,
            Resim: "",
            PopupId: 0,
            popup: false,
            Urunler: [],
            UrunGruplari: [],
        };
        //this.getData();

    }
    //#region form fonksiyonları

    popupGizle() {
        this.setState({
            popup: false
        });
    }

    async getData() {
        await this.getSetting()
            .then(res => {


            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await this.getSitePage()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });







    }

    //#endregion

    //#region ui fonksiyonları

    //#endregion

    //#region servis metodları

    async getSitePage() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getSiteSayfa/" + firmaid, null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            Sayfalar: res.data.Data
                        });
                        resolve("Sayfa bilgileri getirildi.")
                    }
                    else
                        reject(res.data.ErrorMessage);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    async getSetting() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getSiteAyar/" + firmaid, null)
                .then(res => {
                    if (res.data.Success) {
                        const ayar = res.data.Data[0];
                        this.setState({
                            FacebookAdresi: ayar.facebookadresi,
                            TwitterAdresi: ayar.twitteradresi,
                            YoutubeAdresi: ayar.youtubeadresi,
                            LinkedinAdresi: ayar.linkedinadresi,
                            InstagramAdresi: ayar.instagramadresi,
                            WhatsappTel: ayar.whatsapptel,
                            Telefon: ayar.telefon,
                            SayfaIciLink: ayar.sayfaicilink,
                            SiteBaslik: ayar.sitebaslik,
                            Logo: ayar.logo,
                            TelefonBitisik: ayar.telefon.trim(),
                            popup: (ayar.popupgoster === null | ayar.popupgoster === undefined) ? false : ayar.popupgoster
                        });
                        resolve("Ayar bilgisi getirildi...");
                    } else {
                        notify(res.data.ErrorMessage, "error", 3000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }


    //#endregion

    componentDidMount() {
        this.getData();
        this.getUrunler();
    }

    getUrunler() {
        service.get(siteApi + "webmusteri/getQrMenuler/" + firmaid)
            .then(res => {
                if (res.data.Success) {
                    this.setState({
                        // Urunler: res.data.Data.Urunler.filter(x => x.KurumsalSiteGizle === false && x.BirimFiyat > 0),
                        Urunler: res.data.Data.Urunler.filter(x => x.BirimFiyat > 0),
                        // UrunGruplari: res.data.Data.UrunGruplari.filter(x => x.KurumsalSiteGizle === false),
                        UrunGruplari: res.data.Data.UrunGruplari,
                        Firma: res.data.Data.Firma === null ? {} : res.data.Data.Firma,
                        Hakkimizda: res.data.Data.Hakkimizda === null ? "" : res.data.Data.Hakkimizda,
                        CalismaSaatleri: res.data.Data.FirmaCalismaSaatleri
                    });
                }
            });

    }

    getUrunListesi(id) {
        this.setState({
            UrunlerHtml: id
        });
    }
    render() {
        return (
            <React.Fragment>
                <section id="detailPage">
                    <div className="headerContainer">
                        <div className="container d-flex align-items-end justify-content-between">
                            <Logo Logo={YuklenenLogoUrl + this.state.Logo} Baslik={this.state.SiteBaslik}></Logo>
                            <div className="headerRight">
                                <MenuBtn />
                                <RightTop
                                    facebookadresi={this.state.FacebookAdresi}
                                    twitteradresi={this.state.TwitterAdresi}
                                    youtubeadresi={this.state.YoutubeAdresi}
                                    linkedinadresi={this.state.LinkedinAdresi}
                                    instagramadresi={this.state.InstagramAdresi}
                                    telefonbitisik={this.state.TelefonBitisik}
                                    telefon={this.state.Telefon}
                                    SayfaIciLink={this.state.SayfaIciLink} />
                                <RightBottom Sayfalar={this.state.Sayfalar} PropDetay={this.props} />
                            </div>
                        </div>
                    </div>
                    <DetailHeader Baslik="Qr Menu" />
                    <div class="detailContainer">
                        <div className="container">
                            <nav className="menu">
                                <div className="menu-list" id="menu-list">
                                    {this.state.UrunGruplari.map((satir, index) =>
                                        <button key={satir.Id} className={"btn btn-category"} onClick={() => {
                                            this.getUrunListesi(satir.Id);
                                        }}>{satir.Adi}</button>)}
                                </div>
                            </nav>
                            <div className="products">
                                <div className="product-list">
                                    {
                                        this.state.Urunler.filter(x => x.UrunGrubu.Id === (this.state.UrunlerHtml === 0
                                            ? this.state.UrunGruplari[0].Id
                                            : this.state.UrunlerHtml)).map(satir =>
                                                <div className="product col-12 col-lg-6" key={satir.Id}>
                                                    <div className="product-img"><a className="image-link" href={"/menu/" + firmaid}><img src={satir.Resim === null | satir.Resim === undefined ? "/images/menu_joker_gorsel.jpg" : urunUrl + "kucukresimler/" + satir.Resim} alt="product" /></a></div>
                                                    <div className="product-info">
                                                        <h3 className="product-name">{satir.Adi}</h3>
                                                        <div className="product-desc">
                                                            {satir.Aciklama}
                                                        </div>
                                                        <div className="price">
                                                            <span className="new">{satir.BirimFiyat} TL</span>
                                                            {/* <span className="old">26 TL</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* whatsapp başlangıç  */}
                    {/* <Whatsapp whatsapptel={this.state.WhatsappTel}></Whatsapp> */}
                    {/* whatsapp bitiş  */}

                    {/* footer başlangıç  */}
                    <MainFooter
                        Menuler={this.state.Sayfalar}
                        Subeler={this.state.Subeler}
                        FacebookAdresi={this.state.FacebookAdresi}
                        TwitterAdresi={this.state.TwitterAdresi}
                        YoutubeAdresi={this.state.YoutubeAdresi}
                        LinkedinAdresi={this.state.LinkedinAdresi}
                        InstagramAdresi={this.state.InstagramAdresi}
                        SayfaIciLink={this.state.SayfaIciLink} />
                    {/* footer bitiş  */}
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(QrMenu);