import * as React from 'react';
import Nav from './Nav';
export default class RightBottom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="rightBottom">
                    <div className="headerMenu">
                        <ul>
                            {this.props.Sayfalar.filter(x => x.ustmenu === true)
                                .sort((a, b) => (a.sira > b.sira ? 1 : -1))
                                .map((data, i) => {
                                    return <Nav key={i}
                                        AcilisSekli={data.acilissekli}
                                        EkranBaslik={data.ekranbaslik.toUpperCase()}
                                        SeoUrl={data.seourl}
                                        OzelSayfa={data.ozelsayfa}
                                        PropDetay={this.props.PropDetay}
                                    ></Nav>
                                })}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}