import * as React from 'react';
export default class DetailLeftCol extends React.Component {
    createLink(seourl, ekranbaslik, acilissekli) {
        if (acilissekli === 1)
            return <a href={seourl} target="_self" rel="noreferrer">{ekranbaslik}</a>
        else if (acilissekli === 2)
            return <a href={seourl} target="_blank" rel="noreferrer">{ekranbaslik}</a>
        else
            return <a href={"#" + seourl.replace("/", "")} rel="noreferrer">{ekranbaslik}</a>
    }
    render() {
        return (
            <React.Fragment>
                <div className="detailLeftCol">
                    <div className="detailMenu">
                        <div className="btnDetailMenu">MENÜ <i className="fas fa-chevron-down"></i></div>
                        <ul>
                            {this.props.Menuler.map((menu, i) => {
                                if (menu.kurumsalsayfa === true) {
                                    return <li className={(menu.kodu === this.props.AktifMenu ? "active" : "")} key={i}>{this.createLink("/" + menu.seourl, menu.ekranbaslik, menu.acilissekli)}</li>
                                }
                                else {
                                    return <li className={(menu.kodu === this.props.AktifMenu ? "active" : "")} key={i}>{this.createLink(menu.seourl, menu.ekranbaslik, menu.acilissekli)}</li>
                                }

                            })}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}