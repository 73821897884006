import * as React from 'react';
export default class DetailHeader extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="detailHeader bgHeader">
                    <div className="container">
                        <h1>{this.props.Baslik}</h1>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}