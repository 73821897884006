import * as React from 'react';
export default class FooterMenu extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="footerMenu">
                    <h2>MENÜ</h2>
                    <ul>
                        {this.props.Menuler
                            .filter(x => x.altmenu === true)
                            .sort((a, b) => (a.sira > b.sira ? 1 : -1))
                            .map((menu, i) => {
                                if (menu.acilissekli === 1 & !menu.ozelsayfa) {
                                    return <li key={i}><a href={"/" + menu.seourl} target="_self" rel="noreferrer">{menu.ekranbaslik}</a></li>;
                                }
                                else if (menu.acilissekli === 1 & menu.ozelsayfa) {
                                    return <li key={i}><a href={menu.seourl} target="_self" rel="noreferrer">{menu.ekranbaslik}</a></li>;
                                }
                                else if (menu.acilissekli === 2 & !menu.ozelsayfa) {
                                    return <li key={i}><a href={"/" + menu.seourl} target="_blank" rel="noreferrer">{menu.ekranbaslik}</a></li>;
                                }
                                else if (menu.acilissekli === 2 & menu.ozelsayfa) {
                                    return <li key={i}><a href={menu.seourl} target="_blank" rel="noreferrer">{menu.ekranbaslik}</a></li>;
                                }
                                else if (menu.acilissekli === 3) {
                                    return <li key={i}><a href={"#" + menu.seourl} rel="noreferrer">{menu.ekranbaslik}</a></li>;
                                }
                                return <li key={i}><a href={"/" + menu.seourl}>{menu.ekranbaslik.toUpperCase()}</a></li>
                            })}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}