import * as React from 'react';
import { siteApi,firmaid } from '../../Utils';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import $ from 'jquery';
export default class Form extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ulkeler: [],
            iller: [],
            ilceler: [],
            Ulke_Id: 0,
            Il_Id: 0,
            Ilce_Id: 0,
            Ad: "",
            Soyad: "",
            GsmBir: "",
            GsmIki: "",
            TelBir: "",
            TelIki: "",
            Email: ""
        }

        this.getUlkeler()
            .then(res => { })
            .catch(error => {
                notify(error, "error", 3000);
            })
    }

    //#region  servis metodları
    async getUlkeler() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getUlkeler", null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            ulkeler: res.data.Data
                        });
                        resolve("ülke bilgileri getirildi.");
                    }
                    else {
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })

    }
    async getIller(ulkeId) {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getIller/" + ulkeId, null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            iller: res.data.Data
                        });
                        resolve("il bilgileri getirildi.");
                    }
                    else {
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })

    }
    async getIlceler(ilid) {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getIlceler/" + ilid, null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            ilceler: res.data.Data
                        });
                        resolve("ilçe bilgileri getirildi.");
                    }
                    else {
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        })

    }
    //#endregion
    render() {
        return (
            <React.Fragment>
                <div className="basvuruForm mT30">
                    <div className="formContainer d-flex flex-wrap mT30">
                        <div className="formItem col-6 pB20">
                            <label htmlFor="">Ülke</label>
                            <div className="selectbox">
                                <select name="" id="" className="inputDropdown basvuru-il" onChange={async (e) => {
                                    this.setState({
                                        Ulke_Id: parseInt(e.target.value)
                                    });
                                    await this.getIller(e.target.value)
                                        .then(res => {


                                        })
                                        .catch(() => {
                                            this.setState(
                                                {
                                                    Ulke_Id: 0,
                                                    iller: [],
                                                    ilceler: []
                                                });
                                        })
                                }}>
                                    <option value="0">Ülke Seçiniz</option>
                                    {this.state.ulkeler.map((ulke, i) => {
                                        return <option key={i} value={ulke.id}>{ulke.adi}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="formItem col-6 pL50 pB20">
                            <label htmlFor="">İl</label>
                            <div className="selectbox">
                                <select name="" id="" className="inputDropdown basvuru-il" onChange={async (e) => {
                                    this.setState({
                                        Il_Id: parseInt(e.target.value)
                                    });
                                    await this.getIlceler(e.target.value)
                                        .then(res => {

                                        })
                                        .catch(() => {
                                            this.setState(
                                                {
                                                    Il_Id: 0,
                                                    ilceler: []
                                                });
                                        })
                                }}>
                                    <option value="0">İl Seçiniz</option>
                                    {this.state.iller.map((il, i) => {
                                        return <option key={i} value={il.id}>{il.adi}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="formItem col-6 pB20">
                            <label htmlFor="">İlçe</label>
                            <div className="selectbox">
                                <select name="" id="" className="inputDropdown basvuru-ilce"
                                    onChange={(e) => {
                                        this.setState({
                                            Ilce_Id: parseInt(e.target.value)
                                        })
                                    }}
                                >
                                    <option value="0">İlçe Seçiniz</option>
                                    {this.state.ilceler.map((ilce, i) => {
                                        return <option key={i} value={ilce.id}>{ilce.adi}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="formItem col-6 pL50 pB20">
                            <label htmlFor="">Adınız</label>
                            <input type="text" className="inputText basvuru-name" placeholder="Adınız" onChange={(e) => {
                                this.setState({
                                    Ad: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-6 pB20">
                            <label htmlFor="">Soyadınız</label>
                            <input type="text" className="inputText basvuru-surname" placeholder="Soyadınız" onChange={(e) => {
                                this.setState({
                                    Soyad: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-6 pL50 pB20">
                            <label htmlFor="">Mobil Telefon Numaranız</label>
                            <div className="col-12 d-flex">
                                <div className="col-2"><input type="text" maxLength="3" className="inputText basvuru-mobil1" placeholder="5XX" onChange={(e) => {
                                    this.setState({
                                        GsmBir: e.target.value
                                    });
                                }} /></div>
                                <div className="col-10 pL10"><input type="text" maxLength="7" className="inputText basvuru-mobil2" placeholder="XXX XX XX" onChange={(e) => {
                                    this.setState({
                                        GsmIki: e.target.value
                                    });
                                }} /></div>
                            </div>
                        </div>
                        <div className="formItem col-6 pB20">
                            <label htmlFor="">Sabit Telefon Numaranız</label>
                            <div className="col-12 d-flex">
                                <div className="col-2"><input type="text" maxLength="3" className="inputText basvuru-sabit1" placeholder="1XX" onChange={(e) => {
                                    this.setState({
                                        TelBir: e.target.value
                                    });
                                }} /></div>
                                <div className="col-10 pL10"><input type="text" maxLength="7" className="inputText basvuru-sabit2" placeholder="1XX XX XX" onChange={(e) => {
                                    this.setState({
                                        TelIki: e.target.value
                                    });
                                }} /></div>
                            </div>
                        </div>
                        <div className="formItem col-6 pL50">
                            <label htmlFor="">E-posta Adresiniz</label>
                            <input type="text" className="inputText basvuru-eposta" placeholder="isim@firmaismi.com" onChange={(e) => {
                                this.setState({
                                    Email: e.target.value
                                });
                            }} />
                        </div>
                        <div className="formItem col-6">
                            <label htmlFor="">&nbsp;</label>
                            <button className="basvuruFormuSend" onClick={
                                () => {
                                    let data = {
                                        Ad: this.state.Ad,
                                        Soyad: this.state.Soyad,
                                        Email: this.state.Email,
                                        TelBir: this.state.TelBir,
                                        TelIki: this.state.TelIki,
                                        GsmBir: this.state.GsmBir,
                                        GsmIki: this.state.GsmIki,
                                        Ulke_Id: this.state.Ulke_Id,
                                        Il_Id: this.state.Il_Id,
                                        Ilce_Id: this.state.Ilce_Id,
                                        Firma_Id:firmaid
                                    }
                                    service.post(siteApi + "sitearayuz/postBayiBasvuruForm"
                                        , data
                                        , null)
                                        .then(res => {
                                            if (res.data.Success) {
                                                var name = $('.basvuru-name').val();
                                                var surname = $('.basvuru-surname').val();
                                                var eposta = $('.basvuru-eposta').val();
                                                var basvuruil = $('.basvuru-il').val();
                                                var basvuruilce = $('.basvuru-ilce').val();
                                                var mobil1 = $('.basvuru-mobil1').val();
                                                var mobil2 = $('.basvuru-mobil2').val();
                                                var sabit1 = $('.basvuru-sabit1').val();
                                                var sabit2 = $('.basvuru-sabit2').val();


                                                if (basvuruil === '') {
                                                    $('.basvuru-il').parent('div').addClass('warnInput');
                                                } else {
                                                    $('.basvuru-il').parent('div').removeClass('warnInput');
                                                }

                                                if (basvuruilce === '') {
                                                    $('.basvuru-ilce').parent('div').addClass('warnInput');
                                                } else {
                                                    $('.basvuru-ilce').parent('div').removeClass('warnInput');
                                                }

                                                if (name === '') {
                                                    $('.basvuru-name').addClass('warnInput');
                                                    $('.basvuru-name').attr('placeholder', 'Lütfen adınızı giriniz...');
                                                } else {
                                                    $('.basvuru-name').removeClass('warnInput');
                                                    $('.basvuru-name').attr('placeholder', 'Adınız');
                                                }

                                                if (surname === '') {
                                                    $('.basvuru-surname').addClass('warnInput');
                                                    $('.basvuru-surname').attr('placeholder', 'Lütfen soyadınızı giriniz...');
                                                } else {
                                                    $('.basvuru-surname').removeClass('warnInput');
                                                    $('.basvuru-surname').attr('placeholder', 'Soyadınız');
                                                }

                                                if (eposta === '') {
                                                    $('.basvuru-eposta').addClass('warnInput');
                                                    $('.basvuru-eposta').attr('placeholder', 'Lütfen eposta adresi giriniz...');
                                                } else {
                                                    $('.basvuru-eposta').removeClass('warnInput');
                                                    $('.basvuru-eposta').attr('placeholder', 'Soyadınız');
                                                }

                                                if (mobil1 === '') {
                                                    $('.basvuru-mobil1').addClass('warnInput');
                                                    $('.basvuru-mobil1').attr('placeholder', 'Lütfen mobil numaranızı giriniz...');
                                                } else {
                                                    $('.basvuru-mobil1').removeClass('warnInput');
                                                    $('.basvuru-mobil1').attr('placeholder', 'Soyadınız');
                                                }

                                                if (mobil2 === '') {
                                                    $('.basvuru-mobil2').addClass('warnInput');
                                                    $('.basvuru-mobil2').attr('placeholder', 'Lütfen mobil numaranızı giriniz...');
                                                } else {
                                                    $('.basvuru-mobil2').removeClass('warnInput');
                                                    $('.basvuru-mobil2').attr('placeholder', 'Soyadınız');
                                                }

                                                if (sabit1 === '') {
                                                    $('.basvuru-sabit1').addClass('warnInput');
                                                    $('.basvuru-sabit1').attr('placeholder', 'Lütfen telefon numaranızı giriniz...');
                                                } else {
                                                    $('.basvuru-sabit1').removeClass('warnInput');
                                                    $('.basvuru-sabit1').attr('placeholder', 'Soyadınız');
                                                }

                                                if (sabit2 === '') {
                                                    $('.basvuru-sabit2').addClass('warnInput');
                                                    $('.basvuru-sabit2').attr('placeholder', 'Lütfen telefon numaranızı giriniz...');
                                                } else {
                                                    $('.basvuru-sabit2').removeClass('warnInput');
                                                    $('.basvuru-sabit2').attr('placeholder', 'Soyadınız');
                                                }

                                                if (basvuruil !== '' && basvuruilce !== '' && name !== '' && surname !== '' && eposta !== '' && mobil1 !== '' && mobil2 !== '') {
                                                    $('.basvuruForm .formContainer').html('<span class="success">Form başarıyla gönderildi <br/> Zaman ayırdığınız için teşekür ederiz</span>')
                                                }
                                                notify("Başvurunuz alındı", "success", 3000);
                                            } else {
                                                notify("Teknik bir hata oluştu, hata ayrıntısı:" + res.data.ErrorMessage, "error", 3000);
                                            }
                                        })
                                        .catch(error => {
                                            notify("Teknik bir hata oluştu, hata ayrıntısı:" + error, "error", 3000);
                                        })
                                }
                            }>BAŞVUR</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}