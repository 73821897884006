import * as React from 'react';
import { siteApi, YuklenenHaberResimUrl, firmaid, YuklenenLogoUrl } from '../../Utils';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import Logo from '../../components/Header/Logo';
import MenuBtn from '../../components/Header/MenuBtn';
import RightTop from '../../components/Header/RightTop';
import RightBottom from '../../components/Header/RightBottom';
import DetailHeader from '../../components/Header/DetailHeader';
import MainFooter from '../../components/Footer/MainFooter';
import HaberDetailLeftCol from '../../components/DetailContainer/HaberDetailLeftCol';
import DetailRightCol from '../../components/DetailContainer/DetailRightCol';
// import Whatsapp from '../../components/Widgets/Whatsapp';
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router";
class HaberDetay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            EmailAdresi: "",
            Telefon: "",
            WhatsappTel: "",
            TelefonBitisik: "",
            SiteBaslik: "",
            FacebookAdresi: "",
            YoutubeAdresi: "",
            TwitterAdresi: "",
            InstagramAdresi: "",
            LinkedinAdresi: "",
            DribbleAdresi: "",
            FooterAdres: "",
            FooterCopyright: "",
            FooterArkaPlan: "",
            SayfaIciLink: "",
            SecilenHaber: {},
            Logo: "",
            EbultenMail: null,
            Sayfalar: [],
            Subeler: [],
            id: this.props.match.params.id
        };
        this.getData();

    }

    //#region form fonksiyonları

    async getData() {

        await this.getHaber()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await this.getSetting()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await this.getSitePage()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });


    }

    //#endregion


    //#region servis metodları

    async getSitePage() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getSiteSayfa/" + firmaid, null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            Sayfalar: res.data.Data
                        });
                        resolve("Sayfa bilgileri getirildi.")
                    }
                    else
                        reject(res.data.ErrorMessage);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    async getSetting() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getSiteAyar/" + firmaid, null)
                .then(res => {
                    if (res.data.Success) {
                        const ayar = res.data.Data[0];
                        this.setState({
                            FacebookAdresi: ayar.facebookadresi,
                            TwitterAdresi: ayar.twitteradresi,
                            YoutubeAdresi: ayar.youtubeadresi,
                            LinkedinAdresi: ayar.linkedinadresi,
                            WhatsappTel: ayar.whatsapptel,
                            InstagramAdresi: ayar.instagramadresi,
                            Telefon: ayar.telefon,
                            SiteBaslik: ayar.sitebaslik,
                            Logo: ayar.logo,
                            TelefonBitisik: ayar.telefon.trim(),
                            SayfaIciLink: ayar.sayfaicilink
                        });
                        resolve("Ayar bilgisi getirildi...");
                    } else {
                        notify(res.data.ErrorMessage, "error", 3000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    async getHaber() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getHaber/" + this.state.id, null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            SecilenHaber: res.data.Data[0]
                        });

                        resolve("Haber bilgisi getirildi...");
                    } else {
                        notify(res.data.ErrorMessage, "error", 3000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }
    //#endregion

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.state.SecilenHaber.baslik}</title>
                    <meta name="description" content={this.state.SecilenHaber.baslik} />
                </Helmet>
                <section id="detailPage">

                    <div className="headerContainer">
                        <div className="container d-flex align-items-end justify-content-between">
                            <Logo Logo={YuklenenLogoUrl + this.state.Logo} Baslik={this.state.SiteBaslik}></Logo>
                            <div className="headerRight">
                                <MenuBtn />
                                <RightTop
                                    facebookadresi={this.state.FacebookAdresi}
                                    twitteradresi={this.state.TwitterAdresi}
                                    youtubeadresi={this.state.YoutubeAdresi}
                                    linkedinadresi={this.state.LinkedinAdresi}
                                    instagramadresi={this.state.InstagramAdresi}
                                    telefonbitisik={this.state.TelefonBitisik}
                                    telefon={this.state.Telefon}
                                    SayfaIciLink={this.state.SayfaIciLink} />
                                <RightBottom Sayfalar={this.state.Sayfalar} PropDetay={this.props} />
                            </div>
                        </div>
                    </div>


                    {/* detailheader başlangıç  */}
                    <DetailHeader Baslik={this.state.SecilenHaber !== null ? this.state.SecilenHaber.baslik : "Tanımsız sayfa"} />
                    {/* detailheader bitiş  */}

                    {/* detailcontainer başlangıç  */}
                    <div className="detailContainer">
                        <div className="container">
                            <div className="allNews">
                                <a href="/haberler"> <img src="/images/icon/news-back.png" alt="" /> Tüm Haberler</a>
                            </div>
                        </div>
                        <div className="container col-two news-detail d-flex align-items-start justify-content-between">
                            <HaberDetailLeftCol Resim={YuklenenHaberResimUrl + this.state.SecilenHaber.resim} />
                            <DetailRightCol Detay={this.state.SecilenHaber.detay} />
                        </div>
                    </div>
                    {/* detailcontainer bitiş  */}

                    {/* whatsapp başlangıç  */}
                    {/* <Whatsapp whatsapptel={this.state.WhatsappTel}></Whatsapp> */}
                    {/* whatsapp bitiş  */}

                    {/* footer başlangıç  */}
                    <MainFooter
                        Menuler={this.state.Sayfalar}
                        FacebookAdresi={this.state.FacebookAdresi}
                        TwitterAdresi={this.state.TwitterAdresi}
                        YoutubeAdresi={this.state.YoutubeAdresi}
                        LinkedinAdresi={this.state.LinkedinAdresi}
                        InstagramAdresi={this.state.InstagramAdresi}
                        SayfaIciLink={this.state.SayfaIciLink} />
                    {/* footer bitiş  */}
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(HaberDetay);