import React from 'react';
export default class Nav extends React.Component {



    render() {
        console.log(this.props.PropDetay);
        var navUrl = this.props.PropDetay !== undefined ? this.props.PropDetay.location.pathname : "";


        // console.log(window.location.pathname);
        if (this.props.AcilisSekli === 1 & !this.props.OzelSayfa) {
            var currentUrl = "/" + this.props.SeoUrl;
            return <li><a className={navUrl === currentUrl ? "active" : ""} href={"/" + this.props.SeoUrl} target="_self" rel="noreferrer">{this.props.EkranBaslik}</a></li>;
        }
        else if (this.props.AcilisSekli === 1 & this.props.OzelSayfa) {
            var currentUrl = this.props.SeoUrl;
            return <li><a className={navUrl === currentUrl ? "active" : ""} href={this.props.SeoUrl} target="_self" rel="noreferrer">{this.props.EkranBaslik}</a></li>;
        }
        else if (this.props.AcilisSekli === 2 & !this.props.OzelSayfa) {
            var currentUrl = "/" + this.props.SeoUrl;
            return <li><a className={navUrl === currentUrl ? "active" : ""} href={"/" + this.props.SeoUrl} target="_blank" rel="noreferrer">{this.props.EkranBaslik}</a></li>;
        }
        else if (this.props.AcilisSekli === 2 & this.props.OzelSayfa) {
            var currentUrl = this.props.SeoUrl;
            return <li><a className={navUrl === currentUrl ? "active" : ""} href={this.props.SeoUrl} target="_blank" rel="noreferrer">{this.props.EkranBaslik}</a></li>;
        }
        else if (this.props.AcilisSekli === 3) {
            var currentUrl = "#" + this.props.SeoUrl;
            return <li><a className={navUrl === currentUrl ? "active" : ""} href={"#" + this.props.SeoUrl} rel="noreferrer">{this.props.EkranBaslik}</a></li>;
        }
    }
}